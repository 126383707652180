


    @media screen and (min-width: 320px){
        /* .dropdown-menu.show {
            background-color: white !important
        } */
    }  

@media screen and (min-width: 900px){
    
  
    .sidebar_dropdown{
        display:flex;
   
        justify-content: space-between;
    
        position: relative !important;
        display: block !important;
        /* width: 100%; */
        padding: 0% !important;
        
        
        align-items: center !important;
    }



    .sidebar_dropdown_name_icon_main{
       
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    
    .sidebar_dropdown_icon{
       
      
        width:  25%
       
    }
    .sidebar_dropdown_name{
       text-align: justify;
      
        width:  75%;
  
       
    }

    .sidebar_dropdown_btn{

      
        padding: 5px !important;
        margin: 1px !important;
        width:98% !important;
        background-color: rgb(48,51,54) !important;
        border: none !important;
        height: 5vh!important;
  
            box-shadow: none !important;
    }


    
    .sidebar_dropdown_btn:focus{
 

        background-color: #0093D9 !important;
    }
    .sidebar_dropdown_btn:hover{
 
        background-color: rgb(52, 98, 119) !important;
    }

    .sidebar_dropdown_btn:active{
 
        background-color: #0093D9 !important;
    }
/* 
    .sidebar_dropdown_Menu_Item{
        padding: 5%;
    }
   */
    
    .sidebar_dropdown_Item:focus{

        background-color: rgba(130, 172, 192, 0.835)!important;
        color:#fff !important;
     


    }
    
    .sidebar_dropdown_Item:hover{

        background-color: rgb(200, 210, 214)!important;
        color:#fff !important;
     


    }
    
    .sidebar_dropdown_Item:active{

       
        background-color: rgba(130, 172, 192, 0.835)!important;
        color:#fff !important;


    }
    .sidebar_dropdown_Item{


        font-size: 14px;
        font-family:Ubuntu-r;
        /* padding: 10px 0px 10px 0px !important; */
    }
    .sidebar_dropdown_Menu_Item{
           
      
        padding: 0% !important;
    }

    }
@media screen and (min-width: 1100px){
  

    }

   
@media only screen and (min-width: 1300px)  and (min-height:525px){}  





@media only screen and (min-width: 1300px)  and (min-height:668px){}



    @media screen and (min-width: 1800px){}




    