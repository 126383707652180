.BannerImageDiv {
  position: relative;
}

.BannerImage {
  height: 330px;
  width: 100%;
  margin-bottom: 20px;
}

.BannerText {
  font-weight: 600;

  font-size: 40px;
  position: absolute;
  top: 40%;
  left: 45%;
}

.AboutUsDiscription {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.AboutUsMain h3 {
  font-size: 30px;
  font-weight: bold;
  color: #c01825;
  text-align: center;
  margin-bottom: 35px;
}

.AboutUsMain h4 {
  font-size: 18px;

  text-align: center;
  line-height: 30px;
}

.AboutUsMain p {
  font-size: 16px;

  text-align: justify;
  line-height: 25px;
  margin-top: -0.3%;
}
.visionImage{
  height:300px
}
.OurVisionDiv {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 80px;
}

.OurVisionInnerDiv {
  display: flex;
  flex-direction: row;
}

.OurMissionDiv {
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
}

.OurMissionInnerDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .AboutUsMain {
    margin-top: 8%;
  
  }
  .AboutUsMain p{
    font-size: 18px;
  }
  .BannerText {
    top: 40%;
    left: 40%;
  }

  .OurVisionInnerDiv {
    display: flex;
    flex-direction: column;
  }

  .OurMissionInnerDiv {
    display: flex;
    flex-direction: column-reverse;
  }

  .AboutUsMain h3 {
    margin-bottom: 10px;
  }

  .AboutUsMain h4 {
    text-align: justify;
  }

  .AboutUsMain p {
    padding-top: 10px;
  }

  .AboutUsDiscription {
    padding: 20px;
  }

  .OurVisionDiv {
    padding: 20px;
  }

  .OurMissionDiv {
    padding: 20px;
  }
  .BannerImage{
      height: 290px;
  }
}

@media only screen and (max-width: 576px) {
  .BannerImage {
    display: none;
  }

  .BannerImageDiv {
    background: linear-gradient(rgba(31, 30, 30, 0.5), rgba(31, 30, 30, 0.5)),
      url("../../Assets/images/10x10-About-Banner.jpg");
    height: 200px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;

    background-position: right;
    color: white;
  }

  .AboutUsMain {
    margin-top: 18%;
  }

  .BannerText {
    font-family: "OpenSans-B";
    font-size: 25px;
    top: 40%;
    left: 35%;
  }

  .OurVisionInnerDiv {
    display: flex;
    flex-direction: column;
  }

  .OurMissionInnerDiv {
    display: flex;
    flex-direction: column-reverse;
  }

  .AboutUsMain h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .AboutUsMain h4 {
    font-size: 16px;
    text-align: justify;
  }

  .AboutUsMain p {
    font-size: 14px;
  }
}
