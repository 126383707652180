
.accounts-container{
    background-color: white;
  }
  
  
  .account-navigation-container{
    background-color: #c01825;
    border-bottom-left-radius: 5px;
    height: 100%;
    width: 100%;
  }
  
  
  .user-image-container{
    width: 90px;
    margin: 0 auto;
    height: 90px;
    border-radius: 50%;
    margin-top: 30px;
  }
  .user-image{
    width: 90px !important;
    margin: 0 auto;
    border-radius: 50%;
    cursor: default !important;
    height: 90px !important;
  }
  
  .navigation-options-container > div{
    display:flex;
    flex-direction: column;
    padding:40px 0px;
  }
  
  .navigation-options-container button{
    background-color:transparent;
    color:white;
    margin: 2px 0px;
  }
  
/*   
  .navigation-options-container .btn .active{
    background-color:#1A9EDE !important;
  } */
  
  .navigation-options-container{
    display: flex;
    flex-direction: column;
  }
  
  .navigation-options-container .navigation-button{
    background-color: transparent;
    border: 0px;
    font-size: 16px;
    justify-content: space-between;
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding: 20px 20px;
    cursor: pointer;
    font-family: OpenSans-R;
  }
  
  .bg-active{
    background-color:#C6303B !important;
  }
  
  
  .user-name, .user-email{
    color:white;
  }
  
  .user-name{
    font-family: 'OpenSans-M';
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  
  .user-email{
    font-family:'OpenSans-M';
    font-size:12px;
  }
  
  
  .arrow-image{
    float:right;
  }
  
  
  .image-change-button{
  
  }

  #account-mobile-nav{
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #c01825 ;/* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    display: flex;
    flex-direction: column;
  }


  #account-mobile-nav button{
    background-color: transparent;
    border: 0px;
    font-size: 16px;
    justify-content: space-between;
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    font-family: OpenSans-R;
  }


  #account-mobile-nav .close-mob-menu-button{
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 36px;
    margin-left: 50px;
  }

  .close-mob-menu-button img{
    width:25px;
  }


  .account-components-border{
    box-shadow: 0px 3px 7px -4px #d2d2d2;
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .account-nav-ipad{
      display: none !important;
    }
  }

  
  @media  screen and (min-width:1100px){
    .account-nav-ipad{
      display: block !important;
    }
  }