.DivPosition {
    display: flex;
    align-items: center;
    padding: 30px;
    height: auto;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    margin: 40px 90px;
    height: auto;
}

.Heading {
    align-items: center;
    /* background-image: url(../../Assets/images/Schoolbanner.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    margin-top: 0%;
    width: 100%;
    background-color: transparent;
}

.DivJustifyContent {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.DescriptionText {
    font-size: 16px;
}

.TiltleHeadingFont {
    color: #303030;
    font-weight: 600;
    font-size: 25px;
}

.HeadingFontDiv {
    display: flex;
    flex-direction: column;
}

.DateDiv {
    width: auto;
    height: auto;
    background-color: #c01825;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 5px;
}

.YearDiv {
    margin-top: 5px;
    width: auto;
    height: auto;
    background-color: #4A4A4A;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 2px 0px 2px 8px;
}

.RotateText {
    font-size: 14px;
    font-weight: bold;
    transform: rotate(-90deg);
    color: #ff8400;
}

.DivWidth {
    width: 10%
}

.DivWidthTitleDescription {
    width: 88%;
    margin-top: 15px;
    margin-left: 25px;
}

.DateFont {
    font-size: 60px;
    text-align: center;
    font-weight: 600;
}

.MonthFont {
    font-size: 30px;
    text-align: center;
}

.YearFont {
    font-size: 20px;
    letter-spacing: 7px;
    text-align: center;
}

.BannerFont {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 900px) {
    .DivPosition {
        margin: 0;
    }
    .DivWidthTitleDescription {
        margin-left: 50px;
    }
    .DateDiv {
        width: 100px;
    }
    .YearDiv {
        width: 100px;
    }
}

@media only screen and (max-width: 770px) {
    .Heading {
        margin-top: 65px;
    }
}

@media screen and (max-width:576px) {
    .DivPosition {
        display: flex;
        align-items: center;
        padding: 15px;
        height: auto;
        border: 1px solid #dee2e6;
        border-radius: 10px;
        margin: 10px 20px;
        height: auto;
    }
    .Heading {
        align-items: center;
        /* background-image: url(../../Assets/images/Schoolbanner.jpg); */
        background-repeat: no-repeat;
        background-size: cover;
        height: 200px;
        margin-top: 15%;
        width: 100%;
        background-color: transparent;
    }
    .DivJustifyContent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .DescriptionText {
        font-size: 14px;
    }
    .TiltleHeadingFont {
        color: #303030;
        font-weight: 600;
        font-size: 20px;
    }
    .HeadingFontDiv {
        display: flex;
        flex-direction: column;
    }
    .DateDiv {
        width: auto;
        height: auto;
        background-color: #c01825;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: white;
        align-items: center;
        /* display: flex; */
        justify-content: space-evenly;
        padding: 10px;
    }
    .YearDiv {
        margin-top: 5px;
        width: auto;
        height: auto;
        background-color: #4A4A4A;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding: 2px 0px 2px 8px;
    }
    .RotateText {
        font-size: 14px;
        font-weight: bold;
        transform: rotate(-90deg);
        color: #ff8400;
    }
    .DivWidth {
        width: 40%
    }
    .DivWidthTitleDescription {
        width: 100%;
        margin-top: 5px;
        margin-left: 0px;
    }
    .DescriptionText .p {
        margin-bottom: 0px;
    }
    .DateFont {
        font-size: 18px;
        text-align: center;
        font-weight: 600;
    }
    .MonthFont {
        font-size: 18px;
        text-align: center;
    }
    .YearFont {
        font-size: 15px;
        letter-spacing: 7px;
        text-align: center;
    }
    .BannerFont {
        font-size: 40px;
        font-weight: 600;
        color: #fff;
    }
    .news-tab-btn-div {
        width: 100%;
        justify-content: center;
    }

.description-font-size{

    font-size: 16px;
}

}