    
    .carousel .carousel-indicators li {       
        width: 0.625rem;        
        height: 0.225rem;       
        border-radius: 0%;        
        cursor: pointer;    
    }
    /* .carousel-indicators {        
        position: absolute;        
        right: 0;        
        bottom: 10px;        
        left: 0;        
        z-index: 15;        
        display: flex;        
        justify-content: flex-end;        
        padding-left: 0;        
        margin-right: 15vw;        
        margin-left: initial;        
        list-style: none;    }     */
    .carousel-item.active, .carousel-item-next, .carousel-item-prev {        
        display: flex;        
        justify-content: flex-end !important;    
    }    
    .carousel-indicators li {        
        box-sizing: content-box;        
        flex: 0 1 auto;        
        width: 30px;        
        height: 3px;        
        margin-right: 3px;        
        margin-left: 3px;        
        text-indent: -999px;        
        cursor: pointer;        
        background-color: black;        
        background-clip: padding-box;        
        border-top: 10px solid transparent;        
        border-bottom: 10px solid transparent;        
        opacity: .5;        
        transition: opacity .6s ease;    
    }    
    .carousel .carousel-indicators li {        
        width: 2.625rem;        
        height: 0.225rem;        
        border-radius: 0%;        
        cursor: pointer;    
    }    
    .carousel-indicators .active {        
        opacity: 1;        
        background-color: white;    
    }    
    /* .sliderImage_width {        
        width: 75vw;        
        height: auto;                    
    }    */