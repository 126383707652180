#order-list-page {
    width: 100%;
    overflow: hidden;
}

#order-list-page .order-list-column {
    width: 100%;
}

#order-list-page .pages_table tbody tr td:first-child {
    display: flex;
    flex-direction: column;
    align-items: start;
}

#order-list-page .pages_table tbody tr td:first-child button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-top: 30px;
    color: blue
}


#order-list-page .product-order-details-container {
    border: 1px solid black;
    padding: 12px 50px;
    margin: 10px 40px 0px;
}

.modalReport {
    background-color: red;
}

.order-details-button {
    color: #ff000096 !important;
}

.order-details-button:hover {
    cursor: pointer;
}

.product-order-details-container hr {
    border: 0.5px solid grey !important;
    width: 100%;
    margin: 25px 0px;
}
.mdbIconCross:hover{
    cursor: pointer;
}

.iconModal {
    position: absolute;
    right: 20px;
    top: 10px;
}

.downloadButtonReport{
    display: flex;
    justify-content: center;
}
.downloadButtonReport button{
    outline: none;
    border: none;
}
.headingModal {
    text-align: center;
    font-size: 20px;
}

.modalReport {
    position: fixed;
    top: 45%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 1rem 5rem;
    /* background-color: #797777; */
    color: black;
    width: 40%;
    z-index: 999;
    border: 1px solid black;
    background-color: #f8f7f7;
}

#order-list-page .package-name-column {}

#order-list-page .purchase-date-amount-column {
    display: flex;
    justify-content: space-between;
}

#order-list-page .status-column {}

#order-list-page .transaction-column {
    display: flex;
    justify-content: space-between;
}

#order-list-page .refund-column {}