.my-orders-title{
    font-family: OpenSans-R;
    font-size: 25px;
}

.my-orders-subtitle{
    font-family: OpenSans-LI;
    font-size: 14px;
}

.view_invoice{
    width: 100%;
    padding: 5px;
    color:white
}
.invoice-items{
    margin-right: 10px;
    color: #9c9a9a;
    margin-top: 0px;
    font-size: 12px;
    font-weight: 400;
    font-family: OpenSans-L;
    margin-right: 10px;
    margin-top:0px
}

.view-details-button{
    width: 150px;
    border: 1px solid black;
    border-radius: 5px;
    font-family: OpenSans-L;
    float: right;
    padding: 7px 10px;
    margin: 5px;
    color: white;
    font-size: 16px;
    background-color: black;
}

.back-button{
    width: 150px;
    font-family: OpenSans-L;
    float: right;
    padding: 7px 10px;
    margin: 5px;
    color: white;
    font-size: 16px;
    background-color:#ff6633;

}
.course-thumb-order{
    height: auto;
    width: 105px;
}
.rowDefault {
    display: flex;
    flex-direction: row;
    
}

@media only screen and (max-width: 1024px)   {
    .course-thumb-order{
        width: 85px !important;
        margin-top: -9px !important;
    }
.rowDefault  .course-thumb-order{
    width: 70px;
}
.course-purchase{
    font-size: 17px;
}
.invoice-items{
    font-size: 15px;
}
}


@media only screen and (max-width: 768px)   {
    .course-title{
        font-size: 20px;
    }
    .course-thumb-order{
        margin-top: 20px;
        height: auto;
        width: 90px !important;
    }
    }
@media only screen and (max-width: 525px)   {
    .windowView{
        display: none;
    }
    .course-thumb-order{
        height: 50px;
 
        width: 70px !important;
    }
    .vsMobView{
        display:block;
    }
    .course-title {
        font-size: 14px;
    }
    .course-purchase{
        font-size: 13px;
    }


    .rowDefault {
  padding: 0 20px         ;
        margin-right: 0!important;
    }
  


}
/* @media only screen and (min-width: 900px)   {
    .windowView{
        display: block;
    }

    .vsMobView{
        display:none;
    }
} */
















.my-orders-invoice-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    transition: all 0.15s ease-in-out ;
}


.order-invoice-button:hover{
    box-shadow: 0px 1px 9px 0px #929090;
    color: white !important;
}