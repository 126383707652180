.product-page-course-rating {
    margin-bottom: 10px;
    opacity: 1;
}

input[type=checkbox] {
    color: none !important;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
}

.product-page-course-rating .star-ratings {
    position: unset;
    bottom: unset;
    left: unset;
    margin-right: 10px;
}

.contactUsCloseBtn {
    position: absolute;
    right: 5%;
    top: 1%;
    font-size: 30px;
}

.product-page-course-rating>p {
    margin-bottom: 0px;
}

.buyNow:hover {
    background-color: gray;
    border: 1px solid gray;
    color: white;
}

.addCart:hover {
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
    color: white;
}

.shareIt:hover {
    background-color: #353434;
    border: 1px solid #353434;
}

.Toast2 {
    position: absolute;
    animation-duration: 1s;
    opacity: 1 !important;
    bottom: 0;
    left: 50%;
}

#snackbar {
    min-width: 250px;
    margin-left: -150px;
    background-color: #333333c7;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 50px;
    transition: display .05s fade;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

/* body {
  
    -webkit-overflow-scrolling: touch;
  } */

.productMainThird1Button:hover {
    background-color: rgb(68, 125, 153);
    border: 1px solid rgb(68, 125, 153);
}

.productMainThird1Button:active {
    background-color: rgb(68, 125, 153);
    border: 1px solid rgb(68, 125, 153);
    border-radius: 5px;
}

.CollapseInside {
    margin: 0% 5%;
}

*:focus {
    outline: 0 !important;
}

.contactUsContainer .contactUsForm {
    display: flex;
}

.contactUsContainer {
    transition: transform .3s;
}

.contactUsButtonContainer {
    display: flex;
}

input[type=checkbox] {
    color: none !important;
    width: 15px;
    height: 15px;
}

.vsMobView {
    display: none;
}

.productCourseContentPic1 {
    height: 180px;
    width: 100%;
}

.fa-plus {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.fa-heart {
    font-size: 22px;
}

.StudentsHR {
    border-top: .5px solid #E5E5E5;
    width: 100%;
    /* margin: 4% 0% 4% 0%; */
}

.productCourseContentPic2Text {
    color: #4A4A4A;
    font-size: 25px;
    font-weight: bold;
}

.productCourseContentPic2TextSpan {
    font-weight: 400;
    color: gray;
    font-size: 12px;
    margin-left: 5%;
}

.productMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
}

.productMainFirst {
    font-family: "OpenSans-R";
    font-size: 16px;
    padding: 6px;
    background: rgba(0,0,0,0.3);


    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: #ffffff; */
    height: auto;
    padding: .5% 1% .5% 5%;
}

.productMainFirstText {
    font-weight: 500;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
    word-spacing: 1;
}

.productMainSecond {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: cover;                  
    background-position:center center;
    color: #ffffff
}

.productMainSecondText {
    width: 100%;
    color: white;
    font-family: "OpenSans-R";
    font-size: 16px;
    padding: 6px;
    background: rgba(0,0,0,0.4);
    /* width: 70%; */
    padding: 2% 80px;
}

.productMainSecondTextH2 {
    font-weight: bolder;
    font-size: 30px;
    /* font-family: OpenSans-B; */
}

.productMainSecondTextH5 {
    font-weight: 200;
    font-size: 20px;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
}

.productMainSecondTextp {
    font-weight: 200;
    font-size: 14px;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
}

.productCourseContentTextHeadingH1 {
    font-weight: 200;
    font-size: 30px;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
}

.productMainSecondPic {
    width: 30%;
    padding: .5% 1% .5% 1%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
}

.productCourseContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
}

.productCourseContentText {
    width: 70%;
    padding: 1% 0% 0% 1%;
    background-color: #ffffff;
    border: 1px solid #E5E5E5;
    margin: 60px 1% 0% 5%;
}

.productCourseContentPic {
    z-index: 9;
    width: 24%;
    padding: .5%;
    margin: -13% 6% .5% 1%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    /* justify-content: center; */
    /* align-items: center; */
}

.productCourseContentFullHeading {
    margin: 3% 1% .5% 5%;
}

.productCourseContentPic2 {
    width: 100%;
}

.productCourseContentBoxQuestionBank {
    width: 80% !important;
    height: 40px;
    background-color: #F9F9F9;
    border: 1px solid #E5E5E5;
    display: flex;
    padding-left: 5%;
    margin: 0px 30px
}

.productCourseContentPic6 {
    width: 80%;
    min-height: 42px;
}

.addCart {
    width: 100%;
    height: 40px;
    /* background-color: #c01825; */
    color: black;
    border: 1px solid #E5E5E5;
}

.productCourseContentPic7 {
    width: 80%;
    height: 40px;
}

.buyNow {
    width: 100%;
    height: 40px;
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #ffffff;
}

.productCourseContentPic8 {
    width: 80%;
    height: 40px;
}

.shareIt {
    width: 100%;
    height: 40px;
    background-color: black;
    border: 1px solid black;
    color: #ffffff;
}

.POffproductCourseContentPic5 {
    font-size: 14px;
    line-height: 0px;
    color: #0093D9;
}

.productCourseContentText1 {
    width: 70%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
    font-size: 16px;
}

.productCourseContentText2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
    color: #5A5A5A
}

.productCourseContentText3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    /* font-family: OpenSans-R; */
    color: #5A5A5A
}

.productCourseContentTextInner1 {
    width: 100%;
    margin: 5% 2%;
    display: flex;
    flex-direction: row;
}

.productCourseContentTextInner2 {
    width: 50%;
    margin: 2% 2% .5% 2%;
    display: flex;
    flex-direction: row;
}

.productCourseContentFull {
    width: 100%;
    display: flex;
    height: auto;
}

.productCourseContentFullHeading {
    width: 62%;
    margin: 50px 1% 0% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* font-family: OpenSans-R; */
}

.productCourseContentFullHeadingp {
    font-size: 16px;
}

.productCourseContentFullHeadingH1 {
    font-size: 30px;
    margin-bottom: 0%;
}

.CollapseMain {
    width: 65%;
    padding: 0% 1% 0% 0%;
    margin: 10px 1% 0% 5%
}

.CollapseBtn {
    width: 100% !important;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    background-color: #F4F4F4 !important;
    border: 1px solid #E5E5E5 !important;
    box-shadow: none !important;
    color: #4A4A4A !important;
    margin-top: .5% !important;
    padding: 0% 3% 0% 3%;
}

.CollapseBtn:hover:active:focus {
    background-color: #F4F4F4 !important;
    border: 1px solid #E5E5E5 !important;
    width: 100% !important;
}

.CollapseBtnIcon {
    width: 3%;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
}

.CollapseBtnIntroduction {
    width: 80%;
    display: flex;
    /* font-family: OpenSans-R; */
    font-weight: 600;
    color: #5A5A5A !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
}

.CollapseBtnLectures {
    width: 30%;
    display: flex;
    /* font-family: OpenSans-R; */
    font-weight: 600;
    color: #5A5A5A !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
    margin-right: 7%;
}

.CollapseBtnTime {
    width: 12%;
    display: flex;
    justify-content: flex-end;
    /* font-family: OpenSans-R; */
    font-weight: 600;
    color: #5A5A5A !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
}

.RequrmentMain {
    width: 65%;
    padding: 0%;
    margin: 50px 1% 0% 5%;
    text-align: justify;
}

.RequrmentMainH2 {
    /* font-family: OpenSans-R; */
    font-size: 25px;
    margin-bottom: 0%;
}

.productMainThird1 {
    background-color: transparent;
    margin: 85px -40% 42% 51%;
    transform: rotateZ(90deg);
    width: 110px;
    margin: 110% -40% 42% 51%;
    text-align: center;
    border-radius: 5px;
}

.productMainThird1Button {
    color: #fff;
    background-color: #0093D9;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #0093D9;
}

.RequrmentList {
    margin-left: -2.5%;
}

.RequrmentList1 {
    margin-top: 10px;
    font-weight: 500;
    color: #5A5A5A !important;
}

.RequrmentList2 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5A5A5A !important;
    /* font-family: OpenSans-R; */
}

.RequrmentList3 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5A5A5A !important;
    /* font-family: OpenSans-R; */
}

.Description {
    width: 65%;
    padding: 0%;
    margin: 50px 1% 0% 5%;
}

.DescriptionH1 {
    /* font-family: OpenSans-R; */
    font-size: 30px;
    margin-bottom: 0%;
}

.DescriptionP {
    color: #5A5A5A !important;
}

.review {
    width: 65%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 1% 5%;
    display: flex;
    flex-direction: column;
}

.review1 {
    /* font-family: OpenSans-R; */
    font-size: 30px;
    color: #5A5A5A !important;
    margin-bottom: 0%;
}

.review2 {
    width: 100%;
    padding: 2% 2% 2% 4%;
    background-color: #F4F4F4;
    border: 1px solid #E5E5E5;
    margin-top: 10px;
}

.review2H5 {
    /* font-family: OpenSans-B; */
    font-size: 16px;
    color: #5A5A5A !important;
}

.review2H5P {
    /* font-family: OpenSans-R; */
    font-size: 16px;
    color: #5A5A5A !important;
}

.review2P {
    /* font-family: OpenSans-R; */
    font-size: 14px;
    color: #5A5A5A !important;
}

.review2P1 {
    /* font-family: OpenSans-R; */
    font-size: 18px;
    color: #5A5A5A !important;
}

.addYes {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #E5E5E5;
    /* font-family: OpenSans-R; */
    font-size: 12px;
    color: #5A5A5A !important;
}

.addNo {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #E5E5E5;
    /* font-family: OpenSans-R; */
    font-size: 12px;
    color: #5A5A5A !important;
}

.StudentsAlsobought {
    width: 65%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 0% 5%;
}

.InstructorRating {
    width: 65%;
    padding: 0% 1% 1% 1%;
    margin: 0% 1% 1% 5%;
    display: flex;
    flex-direction: row;
}

.InstructorRating1 {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: auto;
}

.InstructorRating2 {
    width: 75%;
    display: flex;
    flex-direction: column;
}

/* .InstructorRating1RoundPic{

    width: 100%;
    display: flex;
    flex-direction: row;
 } */

.InstructorRating1A {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.InstructorRating1B {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.InstructorRating1C {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.InstructorRating1D {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.logoHeight12 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 2%;
    margin-bottom: 5%;
}

.picAndTime {
    display: flex;
    flex-direction: column;
}

.StudentsAlsoboughtH1 {
    /* font-family: OpenSans-R; */
    font-size: 30px;
    margin-bottom: 0px;
}

.StudentsAlsoboughtHead {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.StudentsAlsoboughtBody {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

}


.StudentsAlsoboughtCosts{
    display: flex;
    padding: 5px 0;

}

.StudentsAlsoboughtHeadH3 {
    font-weight: 600;
    font-size: 18px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
}

.StudentsAlsoboughtHeadP {
    font-weight: 300;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
    margin-bottom: 1%;
}

.StudentsAlsoboughtRateText {
    font-weight: 300;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
}

.StudentsAlsoboughtRateText {
    font-weight: 500;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
}

.StudentsAlsoboughtUserText {
    font-weight: 500;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
}

.StudentsAlsoboughtCostH4 {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #4A4A4A;
}

.StudentsAlsoboughtCostP {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    color: #4A4A4A;
}

.logoHeight1 {
    width: 100%;
  height:120px

}

.StudentsAlsoboughtRateTextStar {
    color: #c01825;
}

.StudentsAlsoboughtHeart {
    color: orange;
}

.StudentsAlsoboughtHeart:hover {
    color: red;
}

.StudentsAlsoboughtHeart:focus {
    color: red;
}

.StudentsAlsoboughtHeart:active {
    color: red;
}

.InstructorRating2h4 {
    font-weight: 600;
    font-size: 20px;
    /* font-family: OpenSans-R; */
    color: #4A4A4A;
}

.InstructorRating2P {
    font-weight: 300;
    font-size: 16px;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
}

.InstructorRating2P1 {
    font-weight: 300;
    font-size: 18px;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
}

.InstructorRating1P {
    font-weight: 300;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
}

.InstructorRating1PSpan {
    font-weight: 600;
    font-size: 14px;
    /* font-family: OpenSans-R; */
    color: #5A5A5A;
}

.alreadyPurchasedText {
    font-size: 14px;
    text-align: center;
    font-style: italic;
}

.container {
    padding-left: 0px !important;
}

.RequrmentMain .productHR {
    margin-top: 50px;
}

.Description .productHR {
    margin-top: 50px
}

.RequrmentMain hr {
    border-top: .5px solid #E5E5E5 !important;
    width: 100%;
    margin: 0%;
    height: 2px !important;
}

#myContact {
    width: 125px;
    transition: all 0.3s ease-in-out
}

.contactUsContainer {
    display: flex;
    right: 0;
    position: fixed;
    z-index: 99;
    top: 20%;
}

.contactUsButton-container {
    justify-content: center;
    position: relative;
}

.contactUsButtonContainer .contactUsButton {
    -webkit-transform: rotateZ(90deg);
    /* Safari prior 9.0 */
    transform: rotateZ(270deg);
    justify-self: center;
    align-self: center;
    background-color: #c01825;
    /* font-family: OpenSans-R; */
    font-size: 18px;
    color: white;
    border: none;
    box-shadow: unset;
    width: 125px;
    position: relative;
    right: -48px;
}

.contactUsContainer .contactUsButton>span {
    -webkit-transform: rotateZ(90deg);
    /* Safari prior 9.0 */
    transform: rotateZ(90deg)
}

.contactUsContainer .contactUsForm {
    flex-direction: column;
    background-color: white;
    border: 5px solid #9a9a9a;
    padding: 20px 57px;
    border-radius: 15px;
    position: relative;
}

.contactUsContainer .contactUsForm h2 {
    text-align: center;
}

.contactInputEmail {
    background-image: none !important;
}

.contactInputText {
    border: 1px solid #9a9a9a !important;
    padding: 5px 10px !important;
}

.POffproductCourseContentPic5 {
    cursor: pointer
}

.StudentAlsoCardDiv {
width: 250px;
    padding: 2px;
    margin-right: 30px;
border: 1px solid #c01825;

}

.StudentAlsoCardInnerDiv {
    height: 100%;
    border: 1px solid #c01825;

}


@media (max-width:1025px) {
    .contactUsContainer {
        top: 36%;
    }
}

@media(max-width:769px) {
    .StudentAlsoCardDiv {
        width: calc( 100vw / 3);
     
    }
    .logoHeight {
        width: 100%;
    }
    .productPageReviewFont {
        font-size: 12px;
    }
    input[type=checkbox] {
        color: none !important;
        width: 15px;
        height: 15px;
    }
    .productCourseContentPic1 {
        height: auto;
        width: 100%;
        justify-content: center;
    }
    .checked {
        color: orange;
    }
    .fa-heart {
        font-size: 18px;
    }
    .StudentsHR {
        border-top: .5px solid #E5E5E5 !important;
        width: 100%;
        margin: 3.5% 0% 0% 0%;
    }
    .productHR {
        border-top: .5px solid #E5E5E5 !important;
        width: 100%;
        margin: 0%;
    }
    .productCourseContentPic2Text {
        color: #4A4A4A;
        font-size: 19px;
        font-weight: bold;
        /* font-family: OpenSans-R; */
        letter-spacing: 1;
        text-align: center;
    }
    .productCourseContentPic2TextSpan {
        font-weight: 400;
        color: gray;
        font-size: 16px;
        margin-left: 5%;
        /* font-family: OpenSans-R; */
        letter-spacing: 1;
    }
    .productMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .StarRatings {
        margin-top: -15px;
    }
    .productMainFirst {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        height: auto;
    }
    .productMainFirstText {
        font-weight: 500;
        font-size: 16px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        word-spacing: 1;
    }
    .productMainSecond {
        width: 100%;
        min-height: 0%;
        display: flex;
        flex-direction: column-reverse;
        color: #ffffff;
        height: auto;
    }
    .productMainSecondText {
        width: 100%;
        padding: 5%;
        min-height: 25vh;
    }
    .productMainSecondTextH2 {
        font-weight: bolder;
        font-size: 25px;
        /* font-family: OpenSans-B; */
    }
    .productMainSecondTextH5 {
        font-weight: 200;
        font-size: 16px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainSecondTextp {
        font-weight: 200;
        font-size: 14px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainThird1 {
        background-color: transparent;
        width: 110px;
        float: right;
        text-align: center;
    }
    .productMainThird1Button {
        background-color: #c01825;
        width: 100%;
        border: 1px solid #c01825;
        color: #fff;
    }
    .productCourseContentTextHeadingH1 {
        font-weight: 200;
        font-size: 20px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainSecondPic {
        width: 30%;
        padding: .5% 1% .5% 1%;
        margin: 3% 5%;
        display: flex;
        flex-direction: column;
        display: none;
    }
    .productCourseContent {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        height: auto;
    }
    .productCourseContentText {
        width: 100%;
        padding: 5%;
        margin: 0%;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
    }
    .productCourseContentPic {
        width: 100%;
        padding: 5%;
        margin: 0%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
    }
    .productCourseContentFullHeading {
        margin: 3% 1% .5% 5%;
    }
    .productCourseContentPic2 {
        width: 100%;
    }
    .productCourseContentBoxQuestionBank {
        width: 100%!important;
        height: 40px;
        background-color: #F9F9F9;
        border: 1px solid #E5E5E5;
        display: flex;
        padding-left: 5%;
        margin: 0px 30px
    }
    .productCourseContentPic6 {
        width: 100%;
        min-height: 42px;
    }
    .addCart {
        width: 100%;
        height: 40px;
        border: 1px solid #E5E5E5;
    }
    .productCourseContentPic7 {
        width: 100%;
        height: 40px;
    }
    .buyNow {
        width: 100%;
        height: 40px;
        background-color: #c01825;
        border: 1px solid #c01825;
        color: #ffffff;
    }
    .productCourseContentPic8 {
        width: 100%;
        height: 40px;
    }
    .shareIt {
        width: 100%;
        height: 40px;
        background-color: black;
        border: 1px solid black;
        color: #ffffff;
    }
    .POffproductCourseContentPic5 {
        font-size: 14px;
        line-height: 0px;
        color: #0093D9;
    }
    .productCourseContentText1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
        padding: 0% 5%;
    }
    .productCourseContentText2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
    }
    .productCourseContentText3 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
    }
    .productCourseContentTextInner1 {
        width: 100%;
        margin: 5% 2%;
        display: flex;
        flex-direction: row;
    }
    .productCourseContentTextInner2 {
        width: 50%;
        margin: 2% 2% .5% 2%;
        display: flex;
        flex-direction: row;
    }
    .productCourseContentFull {
        width: 100%;
        display: flex;
        height: auto;
        justify-content: center;
        padding: 2%
    }
    .productCourseContentFullHeading {
        width: 90%;
        margin: 0%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* font-family: OpenSans-R; */
    }
    .productCourseContentFullHeadingp {
        font-size: 14px;
    }
    .productCourseContentFullHeadingH1 {
        font-size: 14px;
        margin-bottom: 0%;
        font-weight: 600;
    }
    .CollapseMain {
        width: 100%;
        padding: 0% 5% 0% 5%;
        margin: 0%;
    }
    .CollapseBtn {
        width: 100% !important;
        min-height: 60px;
        display: flex;
        flex-direction: row;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: .5% !important;
        z-index: 0;
        padding: 0% 5% 0% 5%;
    }
    .CollapseBtn:hover:active:focus {
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        width: 100% !important;
    }
    .fa-plus {
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .CollapseBtnIntroduction {
        width: 55%;
        display: flex;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        text-align: initial;
        margin-right: 4%;
    }
    .CollapseBtnLectures {
        width: 35%;
        display: flex;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        margin-right: 4%;
        justify-content: flex-end;
        scroll-behavior: smooth;
    }
    .CollapseBtnTime {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        justify-content: flex-end;
        scroll-behavior: smooth;
    }
    .RequrmentMain {
        width: 100%;
        padding: 5%;
        margin: 0%;
    }
    .RequrmentMainH2 {
        /* font-family: OpenSans-R; */
        font-size: 20px;
        margin-bottom: 0%;
    }
    .RequrmentList {
        margin-left: -5%;
        font-size: 14px;
    }
    .RequrmentList1 {
        margin-top: 10px;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .RequrmentList2 {
        margin-top: 2.5%;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .RequrmentList3 {
        margin-top: 2.5%;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .Description {
        width: 100%;
        padding: 5%;
        margin: 0%;
        text-align: justify;
    }
    .DescriptionH1 {
        /* font-family: OpenSans-R; */
        font-size: 20px;
        margin-bottom: 0%;
    }
    .DescriptionP {
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review {
        width: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
    }
    .review1 {
        /* font-family: OpenSans-R; */
        font-size: 24px;
        color: #5A5A5A !important;
        margin-bottom: 0%;
    }
    .review2 {
        width: 100%;
        padding: 2% 2% 2% 4%;
        background-color: #F4F4F4;
        border: 1px solid #E5E5E5;
        margin-top: 10px;
    }
    .review2H5 {
        /* font-family: OpenSans-B; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review2H5P {
        /* font-family: OpenSans-R; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review2P {
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .review2P1 {
        /* font-family: OpenSans-R; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .addYes {
        width: 50px;
        height: 22px;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .addYes:hover {
        background-color: #abee75;
        border: 1px solid #abee75;
        color: #5A5A5A !important;
    }
    .addNo {
        width: 50px;
        height: 22px;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .addNo:hover {
        background-color: #f07474;
        border: 1px solid #f07474;
        color: #5A5A5A !important;
    }
    .StudentsAlsobought {
        width: 100%;
        padding: 5% 10%;
        margin: 0%;
    }
    .StudentsAlsoboughtH1 {
        /* font-family: OpenSans-R; */
        font-size: 24px;
        margin-bottom: 0px;
    }
    .InstructorRating {
        width: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
    }
    .InstructorRating1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 55px;
        margin-bottom: 10%;
    }
    .InstructorRating2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .InstructorRating1RoundPic {
        width: 20%;
        display: flex;
    }
    .InstructorRating1A {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1B {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1C {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1D {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .logoHeight12 {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-left: 2%;
        margin-bottom: 5%;
    }






}

@media(max-width:576px) {
    .logoHeight {
        width: 100%;
    }
    .productPageReviewFont {
        font-size: 12px;
    }
    input[type=checkbox] {
        color: none !important;
        width: 15px;
        height: 15px;
    }
    .productCourseContentPic1 {
        height: 175px;
        width: 85%;
        justify-content: center;
    }
    .checked {
        color: orange;
    }
    .fa-heart {
        font-size: 18px;
    }
    .StudentsHR {
        border-top: .5px solid #E5E5E5 !important;
        width: 100%;
        margin: 3.5% 0% 0% 0%;
    }
    .productHR {
        border-top: .5px solid #E5E5E5 !important;
        width: 100%;
        margin: 0%;
    }
    .productCourseContentPic2Text {
        color: #4A4A4A;
        font-size: 19px;
        font-weight: bold;
        /* font-family: OpenSans-R; */
        letter-spacing: 1;
        text-align: center;
    }
    .productCourseContentPic2TextSpan {
        font-weight: 400;
        color: gray;
        font-size: 12px;
        margin-left: 5%;
        /* font-family: OpenSans-R; */
        letter-spacing: 1;
    }
    .productMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .StarRatings {
        margin-top: -15px;
    }
    .productMainFirst {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        height: auto;
    }
    .productMainFirstText {
        font-weight: 500;
        font-size: 12px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        word-spacing: 1;
    }
    .productMainSecond {
        width: 100%;
        min-height: 0%;
        display: flex;
        flex-direction: column-reverse;
        color: #ffffff;
        height: auto;
    }
    .productMainSecondText {
        width: 100%;
        padding: 5%;
        min-height: 25vh;
        margin-top: 40px;
    }
    .productMainSecondTextH2 {
        font-weight: bolder;
        font-size: 20px;
        /* font-family: OpenSans-B; */
    }
    .productMainSecondTextH5 {
        font-weight: 200;
        font-size: 15px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainSecondTextp {
        font-weight: 200;
        font-size: 13px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainThird1 {
        background-color: transparent;
        width: 110px;
        float: right;
        text-align: center;
    }
    .productMainThird1Button {
        background-color: #c01825;
        width: 100%;
        border: 1px solid #c01825;
        color: #fff;
    }
    .productCourseContentTextHeadingH1 {
        font-weight: 200;
        font-size: 20px;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
    }
    .productMainSecondPic {
        width: 30%;
        padding: .5% 1% .5% 1%;
        margin: 3% 5%;
        display: flex;
        flex-direction: column;
        display: none;
    }
    .productCourseContent {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        height: auto;
    }
    .productCourseContentText {
        width: 100%;
        padding: 5%;
        margin: 0%;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
    }
    .productCourseContentPic {
        width: 100%;
        padding: 5%;
        margin: 0%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
    }
    .productCourseContentFullHeading {
        margin: 3% 1% .5% 5%;
    }
    .productCourseContentPic2 {
        width: 100%;
    }
    .productCourseContentBoxQuestionBank {
        width: 80% !important;
        height: 40px;
        background-color: #F9F9F9;
        border: 1px solid #E5E5E5;
        display: flex;
        padding-left: 5%;
        margin: 0px 30px
    }
    .productCourseContentPic6 {
        width: 80%;
        min-height: 42px;
    }
    .addCart {
        width: 100%;
        height: 40px;
        border: 1px solid #E5E5E5;
    }
    .productCourseContentPic7 {
        width: 80%;
        height: 40px;
    }
    .buyNow {
        width: 100%;
        height: 40px;
        background-color: #c01825;
        border: 1px solid #c01825;
        color: #ffffff;
    }
    .productCourseContentPic8 {
        width: 80%;
        height: 40px;
    }
    .shareIt {
        width: 100%;
        height: 40px;
        background-color: black;
        border: 1px solid black;
        color: #ffffff;
    }
    .POffproductCourseContentPic5 {
        font-size: 14px;
        line-height: 0px;
        color: #0093D9;
    }
    .productCourseContentText1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
        padding: 0% 5%;
    }
    .productCourseContentText2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
    }
    .productCourseContentText3 {
        width: 100%;
        display: flex;
        flex-direction: row;
        word-spacing: 1;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        font-size: 14px;
    }
    .productCourseContentTextInner1 {
        width: 100%;
        margin: 4% 0%;
        display: flex;
        flex-direction: row;
    }
    .productCourseContentTextInner2 {
        width: 50%;
        margin: 2% 2% .5% 2%;
        display: flex;
        flex-direction: row;
    }
    .productCourseContentFull {
        width: 100%;
        display: flex;
        height: auto;
        justify-content: center;
        padding: 2%
    }
    .productCourseContentFullHeading {
        width: 90%;
        margin: 0%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* font-family: OpenSans-R; */
    }
    .productCourseContentFullHeadingp {
        font-size: 14px;
    }
    .productCourseContentFullHeadingH1 {
        font-size: 14px;
        margin-bottom: 0%;
        font-weight: 600;
    }
    .CollapseMain {
        width: 100%;
        padding: 0% 5% 0% 5%;
        margin: 0%;
    }
    .CollapseBtn {
        width: 100% !important;
        min-height: 60px;
        display: flex;
        flex-direction: row;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: .5% !important;
        z-index: 0;
        padding: 0% 5% 0% 5%;
    }
    .CollapseBtn:hover:active:focus {
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        width: 100% !important;
    }
    .fa-plus {
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .CollapseBtnIntroduction {
        width: 55%;
        display: flex;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        text-align: initial;
        margin-right: 4%;
    }
    .CollapseBtnLectures {
        width: 35%;
        display: flex;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        margin-right: 4%;
        justify-content: flex-end;
        scroll-behavior: smooth;
    }
    .CollapseBtnTime {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        /* font-family: OpenSans-R; */
        font-weight: 600;
        color: #5A5A5A !important;
        letter-spacing: 1;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        justify-content: flex-end;
        scroll-behavior: smooth;
    }
    .RequrmentMain {
        width: 100%;
        padding: 5%;
        margin: 0%;
    }
    .RequrmentMainH2 {
        /* font-family: OpenSans-R; */
        font-size: 20px;
        margin-bottom: 0%;
    }
    .RequrmentList {
        margin-left: -5%;
        font-size: 14px;
    }
    .RequrmentList1 {
        margin-top: 10px;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .RequrmentList2 {
        margin-top: 2.5%;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .RequrmentList3 {
        margin-top: 2.5%;
        font-weight: 500;
        color: #5A5A5A !important;
        /* font-family: OpenSans-R; */
    }
    .Description {
        width: 100%;
        padding: 5%;
        margin: 0%;
        text-align: justify;
    }
    .DescriptionH1 {
        /* font-family: OpenSans-R; */
        font-size: 20px;
        margin-bottom: 0%;
    }
    .DescriptionP {
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review {
        width: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
    }
    .review1 {
        /* font-family: OpenSans-R; */
        font-size: 24px;
        color: #5A5A5A !important;
        margin-bottom: 0%;
    }
    .review2 {
        width: 100%;
        padding: 2% 2% 2% 4%;
        background-color: #F4F4F4;
        border: 1px solid #E5E5E5;
        margin-top: 10px;
    }
    .review2H5 {
        /* font-family: OpenSans-B; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review2H5P {
        /* font-family: OpenSans-R; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .review2P {
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .review2P1 {
        /* font-family: OpenSans-R; */
        font-size: 14px;
        color: #5A5A5A !important;
    }
    .addYes {
        width: 50px;
        height: 22px;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .addYes:hover {
        background-color: #abee75;
        border: 1px solid #abee75;
        color: #5A5A5A !important;
    }
    .addNo {
        width: 50px;
        height: 22px;
        background-color: #ffffff;
        border: 1px solid #E5E5E5;
        /* font-family: OpenSans-R; */
        font-size: 12px;
        color: #5A5A5A !important;
    }
    .addNo:hover {
        background-color: #f07474;
        border: 1px solid #f07474;
        color: #5A5A5A !important;
    }
    .StudentsAlsobought {
        width: 100%;
        padding: 5% 10%;
        margin: 0%;
    }
    .StudentsAlsoboughtH1 {
        /* font-family: OpenSans-R; */
        font-size: 24px;
        margin-bottom: 0px;
    }
    .InstructorRating {
        width: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
    }
    .InstructorRating1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 55px;
        margin-bottom: 10%;
    }
    .InstructorRating2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .InstructorRating1RoundPic {
        width: 20%;
        display: flex;
    }
    .InstructorRating1A {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1B {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1C {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .InstructorRating1D {
        width: 20%;
        display: flex;
        flex-direction: row;
        margin: 0% 2% 0% 2%;
    }
    .logoHeight12 {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    .picAndTime {
        display: flex;
        flex-direction: column;
    }
    .StudentsAlsoboughtHead {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
    .StudentsAlsoboughtHeadH3 {
        font-weight: 600;
        font-size: 16px;
        /* font-family: OpenSans-R; */
        color: #4A4A4A;
    }
    .StudentsAlsoboughtHeadP {
        font-weight: 300;
        font-size: 16px;
        /* font-family: OpenSans-R; */
        color: #4A4A4A;
        margin-bottom: 1%;
    }
    .StudentsAlsoboughtRateText {
        font-weight: 300;
        font-size: 16px;
        font-family: OpenSans-R;
        color: #4A4A4A;
    }
    .StudentsAlsoboughtRateText {
        font-weight: 500;
        font-size: 16px;
        /* font-family: OpenSans-R; */
        color: #4A4A4A;
    }
    .StudentsAlsoboughtCostH4 {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
        color: #4A4A4A;
    }
    .StudentsAlsoboughtUserText {
        font-weight: 500;
        font-size: 16px;
        /* font-family: OpenSans-R; */
        color: #4A4A4A;
    }
    .StudentsAlsoboughtCostP {
        font-weight: 300;
        font-size: 16px;
     margin: 0;
        color: #4A4A4A;
    }
    .logoHeight1 {
       width: 100%;
    }
    .StudentsAlsoboughtHeart {
        color: orange;
    }
    .StudentsAlsoboughtHeart:hover {
        color: red;
    }
    .StudentsAlsoboughtHeart:focus {
        color: red;
    }
    .StudentsAlsoboughtHeart:active {
        color: red;
    }
    .InstructorRating2h4 {
        font-weight: 600;
        font-size: 12px;
        /* font-family: OpenSans-R; */
        color: #4A4A4A;
    }
    .InstructorRating2P {
        font-weight: 300;
        font-size: 10px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
    }
    .InstructorRating2P1 {
        font-weight: 300;
        font-size: 12px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
        text-align: justify;
    }
    .InstructorRating1P {
        font-weight: 300;
        font-size: 10px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
    }
    .InstructorRating1PSpan {
        font-weight: 600;
        font-size: 10px;
        /* font-family: OpenSans-R; */
        color: #5A5A5A;
    }
    .vsMobView {
        display: block;
    }
    .contactUsButton {
        display: none;
    }
    .contactUsForm {
        display: none !important;
    }

    .StudentAlsoCardDiv {
        width: 100%
    
    }

}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .productCourseContentPic2Text {
        font-size: 22px;
    }
    .products_productCourseContentPic__Dg5oQ {
        width: 40%;
    }
}

/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {}