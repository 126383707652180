.BannerImage {
  /* background: linear-gradient(rgba(31, 30, 30, 0.5), rgba(31, 30, 30, 0.5)),
    url("../../../Assets/images/highdash.png"); */
  height: 330px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: 50px;
}
.Heading {
  color: #c01825;
  font-size: 29px;
  font-family: "OpenSans-B";
  text-align: center;
}
.BannerText {
  color: white;
  font-family: "OpenSans-B";
  font-size: 40px;
  letter-spacing: 2px;
}
.Form {
  padding: 70px 40px;

  display: flex;
  justify-content: space-between;
}
.Description {
  color: #c01825;
  font-size: 18px;
  font-family: "OpenSans-R";
  text-align: center;
  margin-bottom: 50px;
}
.Contents {
  border: 1px solid black;
  border-radius: 1px;
}
.BannerTextBelow {
  color: #ddd9d9;
  font-family: "OpenSans-R";
  font-size: 16px;
  padding: 6px;
  background: rgba(0,0,0,0.4);
}
.SchoolForm {
  padding: 10px 100px;
  margin-bottom: 50px;
}
.Courses {
  padding: 10px 100px;
}

.TextForm {
  font-family: "OpenSans-B";
  font-size: 21px;
}

.OuterContentCourse {
  border: 1px solid #c01825;
  margin-bottom: 50px;

  padding: 1.5px;
}
.OuterContentCourse:hover{
  cursor: pointer;
}
.OuterContentCourseSubcategories {
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 550;
  border: 1px solid #c01825;
}
.ContentCourse {
  display: flex;
  flex-direction: column;
  height: 420px;
  max-height: 550;
  border: 1px solid #c01825;
  position: relative;
}
.CardImage {
  width: 100%;
  height: 150px;
}
.CardBody {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.grad {
  margin-right: 30px;
  margin-top: -5px;
}
.SelectBox {
  width: 70%;
}
.ShowButton {
  border: none !important;
  border-radius: 0px !important;
  background-color: #c01825;
  color: white;
  padding: 12px 25px;
  margin-bottom: 30px;
}
.CardTitle {
  cursor: pointer;
  font-family: "OpenSans-B";
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 3px;
}
.CardTitle:hover {
  cursor: pointer;
}
.Author {
  margin-bottom: 3px;
  font-size: 13px;
  font-family: "OpenSans-B";
}
.Ratings {
  font-size: 13px;
}
.Lessons {
  margin-top: 10px;
  margin-bottom: 8px;
}
.AverageTime {
  margin-left: 15px;
}
.CourseDurationImage {
  margin-right: 5px;
}
.ButtonDiv {
  display: flex;
  justify-content: center;
}
.TimeImage {
  margin-right: 5px;
}
.LessonsImage {
  margin-right: 5px;
}
.Costs {
  display: flex;
  justify-content: space-between;
}
.OldCost {
  font-size: 19px;
  font-family: "OpenSans-R";
  margin-bottom: 0;
}
.NewCost {
  font-size: 19px;
  font-family: "OpenSans-B";
  margin-left: -50px;
  margin-bottom: 0;
}
.Duration {
  margin-top: 0;
}
.CardButtons {
  display: flex;
  justify-content: center;
  margin-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.AddCart {
  margin: 10px 10px;
  padding: 5px 15px;
  font-size: 16px;
  background-color: #c01825;
  font-family: "OpenSans-R";
  border: none !important;
  color: white;
}
.BuyNow {
  font-family: "OpenSans-R";
  margin: 10px 10px;
  padding: 5px 15px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 3%;
}


.bannerContentWrapper{
  width: 100%;
  color: white;
  font-family: "OpenSans-R";
  font-size: 16px;
  padding: 6px;
  background: rgba(0,0,0,0.4);
  /* width: 70%; */
  padding: 2% 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .Courses {
    min-height: 57.5vh;
  }
  .SelectBox {
    width: 60%;
  }
  .TextForm {
    font-size: 17px;
  }
  .grad {
    margin-right: 20px;
  }
  .SchoolForm {
    padding: 10px 20px;
  }
  .Courses {
    padding-left: 60px;
    padding-right: 60px;
  }
  .BannerText {
    color: white;
    font-family: "OpenSans-B";
    font-size: 25px !important;
    letter-spacing: 2px;
    margin-top: 30px !important;
    text-align: center !important;
  }
  .BannerTextBelow {
    font-size: 19px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .ShowButton {
    padding: 12px 25px;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .SelectBox {
    width: 70%;
  }
  .SchoolForm {
    padding: 10px 60px;
  }
  .Courses {
    padding-left: 30px;
    padding-right: 30px;
  }
  .AddCart {
    font-size: 14px;
    padding: 10px 10px;
    margin-right: 0;
  }
  .BuyNow {
    font-size: 14px;
    padding: 10px 10px;
    /* margin-left: 3px; */
  }
  .BannerTextBelow {
    font-size: 23px;
  }
  .CardTitle {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1800px) {
  .OuterContentCourse {
    padding: 4.3px;
  }
  .OuterContentCourse {
    width: 339px;
  }
}
.dashboardTest {
  display: none;
}

@media screen and (max-width: 868px) {
  .dashboardTest {
    display: none;
  }
  .CardButtons {
    display: flex;
    justify-content: center;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}




@media only screen and (max-width: 576px) {
  .BannerImage{
    height: 250px;
  }
  .ShowButton {
    font-size: 11px;
    padding: 10px 20px;
  }
  .SelectBox {
    margin-top: 20px;
  }
  .Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }
  .Heading {
    color: #c01825;
    font-size: 22px;
    font-family: "OpenSans-B";
    text-align: center;
  }
  .CardButtons {
    margin-top: 2rem;
  }
  .BannerText {
    color: white;
    font-family: "OpenSans-B";
    letter-spacing: 2px;
    font-size: 19px !important;
    margin-top: 30px !important;
    text-align: center !important;
  }
  .BannerTextBelow {
    font-size: 13px !important;
  }
  .Courses {
    padding-left: 60px;
    padding-right: 60px;
  }
  .SchoolForm{
    padding: 10px 28px;
  }
  .ContentCourse{
    height: 390px;
  }
  .CardImage{
    height: 145px;
  }
  .Costs{
    /* padding-top: ; */
    margin-bottom: 55px;
  }
}