.my-courses-title {
    font-family: OpenSans-R;
    font-size: 25px;
}

.my-courses-subtitle {
    font-family: OpenSans-LI;
    font-size: 14px;
}

.text-end {
    text-align: end !important;
}

.course-row>.row {
    align-items: center;
    background-color: #f7f3f3;
    margin: 5px 0px;
    border: 1px solid #dedada;
    border-radius: 5px;
}

.course-row>.row .col-2 {
    padding: 2px;
}

.course-title {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: OpenSans-R;
    font-size: 18px;
}

.course-dates {
    font-family: Ubuntu-LI;
    font-size: 14px;
}

.start-learning {
    padding: 5px;
    font-size: 14px;
    font-family: OpenSans-R;
    background-color: #c01825;
    border: 1px solid #c01825;
    min-width: 140px;
}
.mycourse_width{

max-width: 42.333333%;

}
@media only screen and (max-width: 1800px) {
    .course-thumb {
        width: 99px;
    }
}

@media only screen and (min-width: 1800px) {
    .course-title {
        margin-left: 90px;
    }
    .course-purchase {
        margin-left: 90px;
    }
}

@media only screen and (max-width: 1024px) {
    .course-title {
        margin-top: 10px;
        margin-bottom: 0px;
        font-family: OpenSans-R;
        font-size: 19px;
    }
    .course-dates {
        font-size: 11px;
    }
    .course-thumb {
        width: 88px !important;
        margin-top: -12px;
    }
    .container-c {
        min-height: 63vh;
    }
}

@media only screen and (max-width: 768px) {
    .container-c {
        margin-top: 60px;
        min-height: 100vh;
    }
    .course-thumb {
        height: 91px !important;
        width: 152px !important;
    }
    .outer-div {
        flex-direction: column;
        padding: 20px 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    .course-row>.row {
        margin-bottom: 80px;
    }
    .course-thumb-div {
        display: flex;
        justify-content: center;
    }
    .start-learning {
        min-width: 100px !important;
        margin-top: 0 !important;
    }
    .btn-test {
        width: 100px;
    }
    .render-button-div {
        display: flex;
        justify-content: center;
    }
    .course-title {
        margin-left: 20px;
    }
    .course-dates {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .course-thumb {
        height: 91px;
        width: 152px;
        margin-top: 0px;
    }
    .course-title {
        font-size: 16px !important;
        margin-left: 0px;
    }
    .OpenBook {
        margin-left: 40%;
    }
    .container-c {
        margin-top: 55px;
        min-height: 100vh;
    }
    .render-button-div {
        padding-right: 15px !important;; 
    }

    .mycourse_width{

        max-width: 100%;
        text-align: center;
        }

}