.startTestDecrpition {
    padding: 50px 50px 50px 100px;
    width: 85%;
}

.startTestList {
    margin-top: 10px;
}

.button_and_text {
    margin: -200px 2px 2px 75px;
    position: absolute;
}

.bg_img_div {
    width: 100%;
    height: 400px;
}

.bg_img {
    width: 100%;
    height: 390px;
}

.text_on_test_BGimg {
    font-size: 25px;
    font-family: OpenSans-b;
    color: #fff;
    width: 80%;
}

.buttons_flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.Button_NotNow {
    width: 160px;
    height: 40px;
    background-color: #000;
    border: 1px solid black;
    text-align: center;
    color: #fff;
}

.Button_startTest {
    width: 160px;
    height: 40px;
    background-color: #c01825;
    border: 1px solid #c01825;
    text-align: center;
    color: #FFF0E9;
}

.start-test-page .header {
    width: 100%
}
.startTestDecrpitionH1{

    font-size: 40px;
    font-weight: 600;
}
@media screen and (max-width: 768px) {
    .startTestDecrpition {
        padding: 50px;
        width: 100%;
    }
    .bg_img_div {
        margin-top: 50px;
    }

    .startTestDecrpitionH1{

        font-size: 30px;
        font-weight: 600;
    }
}

@media screen and (max-width: 576px) {
    .button_and_text {
        margin: -205px 2px 2px 20px;
        position: absolute;
    }
    .text_on_test_BGimg {
        font-size: 18px;
  
        width: 100%;
    }

    .Button_startTest {
        width: 115px;
     
    }

    .Button_NotNow {
        width: 115px;
    
    }

    .startTestDecrpition {
        padding: 20px;

    }

    .startTestDecrpitionH1{

        font-size: 20px;
        font-weight: 600;
    }

}


