.cross2 {
    height: 12px;
    width: 12px;
    cursor: pointer;
    margin-right: 5px;
}

.duration_text {
    font-size: 12px
}

.test {
    text-align: center;
    margin-top: 30px;
}

.downloadButton {
    color: blue !important;
}

.downloadButton:hover {
    cursor: pointer;
    scale: 2;
}

.chooseTest {
    margin: 0 auto;
    width: 80%;
}

.inside_collapse {
    padding: 2% 4%;
    flex-direction: column !important;
    align-items: flex-start !important;
    cursor: pointer;
}

.test {
    margin-top: 30px;
}



.courseContentIcon {
    margin-right: 10px;
}

@media only screen and (min-width: 800px) {
    .VideoDiv {
        width: 70% !important;
    }

    .lessonContent {
        width: 70%;
    }

    .cross2 {
        display: block;
    }

    .arrowLeft {
        position: absolute;
        font-size: 70px;
        left: 2%;
        top: 45%;
        color: white;
        width: 40px;
        height: 40px;
        z-index: 999
    }

    .contactUsFormQuery {
        height: 350px;
    }

    .writeQuery {
        font-size: 12px;
    }

    .arrowRight {
        position: absolute;
        font-size: 70px;
        right: 2%;
        top: 45%;
        width: 40px;
        height: 40px;
        color: white;
        z-index: 999
    }

    .pptiframe {
        height: 500px !important;
        width: 100% !important;
    }

    .Test {
        padding-top: 30px;
        text-align: center;
    }

    .TestButton {
        color: white;
        padding: 10px 40px;
        border-radius: 5px;
        background-color: #c01825;
    }

    .TestPara {
        padding: 0 30px;
        color: #c01825;

    }

    .TestHeading {
        /* color: #c01825; */
        font-size: 24px;

    }

    .videolecture {
        height: 500px !important;
    }

    .renderImage {
        display: block;
        margin: 0 auto;
        height: 500px;
        width: auto
    }

    .react-pdf__Page__canvas {
        height: auto !important;
        width: 90% !important;
    }

    #lesson-header .header {
        display: block
    }

    .lessonTopicsID {
        padding-left: 0px
    }

    .lessondetailscontainer {
        display: block;
    }

    .lessonTopicsID {
        width: 30%;
        min-height: fit-content;
        height: 610px;
        overflow-y: scroll;
    }

    .lessonShowContent {
        /* width:70%; */
        margin-top: 0px;
        padding: 0px;
        /* width:100%; */
        overflow-y: scroll;

        height: 80vh;
        position: relative;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }

    .slide-next {
        background-image: url(../../Assets/images/next.png);
        right: 0;
        height: 100px;
        width: 20px;

    }

    .slide-prev {
        background-image: url(../../Assets/images/previous.png);
        left: 0;
        height: 100px;
        width: 20px;

    }


}

@media only screen and (min-width: 1100px) {
    .react-pdf__Page__canvas {
        height: 500px !important;
        width: 720px !important;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }


}




@media only screen and (min-width: 900px) and (min-height:525px) {
    .react-pdf__Page__canvas {
        height: 500px !important;
        width: 720px !important;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }

    .lessonMain {

        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 0px;
    }

    .lessonContent {

        width: 70%;
        display: flex;
        flex-direction: column;

    }




    .lessonTopics .lessonTopicsHeading {



        color: #4A4A4A;
        font-size: 18px;
        font-weight: bold;
        font-family: Ubuntu-r;
        letter-spacing: 1;
    }

    .lessonTopicsHeadingDiv {
        width: 100%;
        display: flex;
        flex-direction: row;

        padding: 6% 5% 6% 5%;
        justify-content: space-between;
    }

    .CourseIntroduction {


        color: #4A4A4A;
        font-size: 14px;
        font-weight: bold;
        font-family: Ubuntu-r;
        letter-spacing: 1;

    }

    .CourseLectures {

        color: #4A4A4A;
        font-size: 12px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;

    }

    .CollapseInside_lesson {

        border-bottom: 1px solid #d2d2d2;
    }


    .react-pdf__Document {
        background-color: black !important;
    }





    .CollapseBtnCourse {
        margin: 0% !important;
    }

    .overView {
        margin-left: 5%;
        margin-right: 5%;
        text-align: justify;
        height: 50px;
        padding-top: 1%;
        width: fit-content;

    }

    .overViewText {

        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;
    }

    .aboutCourse {

        text-align: justify;
        width: 100%
    }

    .aboutCourseHeading {

        color: #4A4A4A;
        font-size: 25px;

        letter-spacing: 1;
        padding-top: 25px;
    }

    .aboutCourseParagraph {
        text-align: justify;
    }


    .Features {
        width: 88%;
        display: flex;
        flex-direction: row;
        margin: 3% 0% 3% 0%;

        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;

    }

    .FeaturesSpan {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-b;
        letter-spacing: 1;
    }

    .Featurestext {
        width: 25%;
    }

    .FeaturesAvailable {
        width: 30%;
    }

    .FeaturesCertifcate {
        width: 30%;
    }

    .description {
        width: 88%;
        display: flex;
        flex-direction: row;
        margin: 3% 0% 3% 0%;

        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;
        text-align: justify;

    }

    .descriptiontext {
        width: 25%;
    }

    .descriptionPara {
        width: 75%;
    }

    .testSeries {
        width: 88%;
        display: flex;
        flex-direction: row;
        margin: 3% 0% 3% 0%;

        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;
        text-align: justify;

    }

    .testSeriestext {
        width: 25%;
        color: #4A4A4A;
        font-size: 25px;
        font-weight: 500;
        font-family: Ubuntu-b;
        letter-spacing: 1;
    }

    .testSeriesTextBtn {
        width: 75%;
    }

    .ButtonTest {
        margin-top: 2%;
    }

    .instructor {

        width: 88%;
        display: flex;
        flex-direction: row;
        margin: 3% 0% 3% 0%;

        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;
        text-align: justify;
    }

    .instructorName {
        /* font-family: 'OpenSans-B'; */
        width: 25%;
    }

    .InstructorName {
        /* font-family: 'OpenSans-B'; */
    }

    .instructorInfo {
        font-family: 'OpenSans-B';
        width: 75%;
    }

    .instructorInfo_pic_sub {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

    }

    .instructorInfosub {
        margin-left: 2%;
        font-family: 'OpenSans-B';
    }

    .instructorInfo {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-b;
        letter-spacing: 1;
        text-align: justify;

    }

    .instructorSub {

        color: #4A4A4A;
        font-size: 14px;


        letter-spacing: 1;
        text-align: justify;
    }

    .instructorSubDescription {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        font-family: Ubuntu-r;
        letter-spacing: 1;
        text-align: justify;

    }

    .descriptionTextHeading {
        color: #4A4A4A;
        font-size: 16px;

        letter-spacing: 1;
        text-align: justify;

    }

    .descriptionTextHeadingDivMargin {
        margin-top: 5%;
    }




}


@media only screen and (min-width: 1300px) and (min-height:668px) {
    .react-pdf__Page__canvas {
        height: 500px !important;
        width: 720px !important;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;

    }

}

.renderopenArrow {
    position: absolute;
    right: 0;
    top: 10px;


}

#lessonContent .react-pdf__Page__canvas {
    margin: 0 auto;
}

/* Large screens ----------- */
.lessonCloseBtn {
    background: #232931;
    margin-right: -100px;
    width: 140px;
    /* position: absolute; */
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    height: 50px;
    border: none;

}

.lessonCloseBtn:hover {
    margin-right: 10px;
    transition: all .4s ease-in;
    /* transition: all .3s ease-out; */
}




.logoHeight {
    width: 40px;
    border-radius: 50%;
}





/* .arrowContainer{
    background-color: black;
} */



.lessonTopicsID {

    /* border-left: 1px solid #d2d2d2; */
}

.subjectName {

    font-size: 22px !important;
    padding-left: 0;
}


.lessondetailscontainer {
    padding-left: 90px
}


@media screen and (max-width: 868px) {
    /* .TestButton {
        display: none;
    }

    .Test {
        display: none;
    } */

    .chooseTest {

        width: 40%;
    }

    .Test {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 30vh;
    }


    .TestPara {
        color: #c01825 !important;

        text-align: justify;
    }

    .TestButton {
        background-color: #c01825 !important;
        padding: 10px 30px;
        color: white !important;
        margin-top: 5%;
    }

    .VideoDiv {
        width: 100% !important;
    }

    .CollapseInside_lesson {
        border-bottom: 1px solid #d2d2d2;
    }

    .arrowLeft {
        position: absolute;
        font-size: 70px;
        left: 2%;
        top: 45%;
        color: white;
        width: 20px;
        height: 20px;
        z-index: 99;
    }

    .arrowRight {
        position: absolute;
        font-size: 70px;
        right: 2%;
        top: 45%;
        width: 20px;
        height: 20px;
        color: white;
        z-index: 99;
    }

    .pptiframe {
        height: auto !important;
        width: 100% !important;
    }

    .videolecture {
        height: 40vh !important;
    }

    .renderImage {
        display: block;
        margin: 0 auto;
        height: 40vh;
        width: auto
    }


    #lesson-header .header {
        display: none;
    }


    .new-header-logo {
        width: 60px
    }

    .lessondetailscontainer {
        display: none;
    }

    .lessonTopicsID {
        width: 100%;
        padding: 10px;
        min-height: 50vh
    }

    .lessonShowContent {
        position: relative;
        background-color: transparent;
        display: flex;
        align-items: center;
        min-height: 80vh;
        width: 100%;

        justify-content: center;
    }

    .VideoDiv {
        width: 70%;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }


    .subjectName {
        font-size: 22px !important;
        padding-left: 0;
        margin-top: 21px;
    }


    .CourseIntroduction {
        color: #4A4A4A;
        font-size: 14px;
        font-weight: bold;
        /* font-family: Ubuntu-r; */
        letter-spacing: 1;
    }


}








@media only screen and (max-width: 576px) {
    .Test {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .TestHeading {
        margin-top: 30px;

    }

    .TestPara {
        color: #c01825 !important;

        text-align: justify;
    }

    .TestButton {
        background-color: #c01825 !important;
        padding: 10px 30px;
        color: white !important;
    }

    .VideoDiv {
        width: 100% !important;
    }

    .CollapseInside_lesson {
        border-bottom: 1px solid #d2d2d2;
    }

    .arrowLeft {
        position: absolute;
        font-size: 70px;
        left: 2%;
        top: 45%;
        color: white;
        width: 20px;
        height: 20px;
        z-index: 99;
    }

    .arrowRight {
        position: absolute;
        font-size: 70px;
        right: 2%;
        top: 45%;
        width: 20px;
        height: 20px;
        color: white;
        z-index: 99;
    }

    .pptiframe {
        height: auto !important;
        width: 100% !important;
    }

    .videolecture {
        height: 40vh !important;
    }

    .renderImage {
        display: block;
        margin: 0 auto;
        height: 40vh;
        width: auto
    }


    #lesson-header .header {
        display: none;
    }


    .new-header-logo {
        width: 60px
    }

    .lessondetailscontainer {
        display: none;
    }

    .lessonTopicsID {
        width: 100%;
        padding: 10px;
        min-height: 50vh
    }

    /* .lessonShowContent{
        position: relative;
        background-color: transparent;
        display: flex;
        align-items: center;
        height: 40vh;
        width:100%;
        margin-top:70px;
        justify-content:center;
    }  */
    .VideoDiv {
        width: 70%;
    }

    .lessontypeicon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }



}