.SignOutMobileHeader {
    display: none;
}

.DisplayHeader {
    display: block;
}

.HomepageHeaderTopPart {
    padding: 0px 0px 0px 80px;
}
.contactlogout{
    opacity: 0;
    
}

.HomepageHeaderBtnSearchBagraounColor {
    background-color: rgb(247, 242, 242);
    clip-path: polygon(12% 0, 100% 0%, 100% 100%, 0% 100%);
    display: flex;
    justify-content: flex-end;
}

.HeadsetLogo {
    margin-left: 5px;
    font-size: 14px;
}

.HomepageHeaderBtnSearch {
    flex-direction: column;
    padding-right: 80px;
}

.InputHeaderSearchDiv {
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;
}

.InputHeaderSearch {
    width: 100%;
    height: 40px;
    padding-left: 10px;
}

.InputHeaderSearch.ul{
    z-index: 999;
}
.SearchIcon {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    /* margin-top: -30px; */
    position: absolute;
    right: 0;
    width: 25px;
    top: 35%;
}

.HeaderRedBtn {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #fff;
    height: 40px;
    width: 100px !important;
    font-size: 16px;
}

.HeaderLangBtn {
    background-color: #fff;
    border: 1px solid #afafaf;
    color: #4A4A4A;
    height: 40px;
    width: 100px !important;
    font-size: 16px;
}

.HeaderBtn {
    background-color: #fff;
    border: 1px solid #afafaf;
    color: #4A4A4A;
    height: 40px;
    width: 100px;
    font-family: OpenSans-R;
    font-size: 16px;
}

.HeaderBtn:hover {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #fff;
    height: 40px;
    width: 100px;
    font-family: OpenSans-R;
    font-size: 16px;
}

/* .HeaderBtn:active {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #fff;
    height: 40px;
    width: 100px;
    font-family: OpenSans-R;
    font-size: 16px;
} */

.HrTag {
    background-color: #c01825;
    margin: 2px;
}

.HomepageHeaderMenus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 80px;
}

.HeaderSocialMedia {
    z-index: 999;
    height: 35px;
    width: 35px;
    margin-right: 5px;
    border: 1px solid #afafaf;
    background-color: transparent;
}

.HeaderSocialMedia:hover {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: white;
}

.ProfileMenuList{
    font-weight: 600;
font-family: OpenSans-R;
font-size: 16px;
margin-right: 16px;
color: #444444;
cursor: pointer;
}
.activeLink{
color:#c01825;
}

.ProfileMenuList:hover {
    color: #c01825;
    
}

.ProfileMenuListContact {
    font-family: OpenSans-R;
    font-size: 16px;
    margin-right: 16px;
    cursor: pointer;
    background-color: #c01825;
    padding: 3px 20px;
    border: 1px solid #c01825;
    color: white;
    /* margin-top: -5px; */
}

.ClassesSubMenuContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: whitesmoke;
    z-index: 9999;
    padding: 20px 30px;
    top: 0px !important;
    /* border-radius: 10px; */
    border: 2px solid #b9b9b9;
    /* margin: 0px 10%; */
    animation-name: homeHeader_examMenuAnimate__1l5DF;
    animation-duration: 0.1s;
}

.ClassesSubMenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    height: 500px;
    align-items: flex-start;
    padding: 20px 5%;
    border-radius: 5px;
}

.RowReverse {
    display: flex;
    flex-direction: row-reverse;
}

.CrossDiv {
    display: flex;
    justify-content: flex-end;
    width: 10%;
    padding-top: 6px;
    flex-direction: row;
    align-items: flex-start;
}

.ClassesMenuLogo {
    width: 1085px;
    display: flex;
    justify-content: center;
    margin-top: 9px;
}

.CrossImg {
    width: 25px;
    height: 25px;
    cursor: pointer
}

.LogoImage {
    width: 120px;
}



@media(max-width:1600px) {
.HomepageHeaderBtnSearch {
    flex-direction: column;
    padding-right: 85px;
}

}

@media(max-width:1024px) {
    .ProfileMenuList {
        font-family: OpenSans-R;
        font-size: 14px;
    }
    .HeaderEnglishBtn {
        font-family: OpenSans-R;
        font-size: 12px;
    }
    .HeaderHindiBtn {
        font-family: OpenSans-R;
        font-size: 12px;
    }
    .HeaderLogInBtn {
        font-family: OpenSans-R;
        font-size: 12px;
    }
    .HeaderSignUpBtn {
        font-family: OpenSans-R;
        font-size: 12px;
    }
    .HeaderSocialMedia {
        height: 30px;
        width: 30px;
        font-size: 14px;
        margin-right: 5px;
    }
}

@media(max-width:1024px) {
    .InputHeaderSearchDiv {
        display: flex;
        flex-direction: column;
        margin-top: 11px;
    }
}

@media(max-width:768px) {
    .SignOutMobileHeader {
        display: block;
    }
    .DisplayHeader {
        display: none;
    }
}

/* Portrait */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .HeaderRedBtn {
        height: 40px;
        width: 100px !important;
        font-size: 13px;
    }
    .HeaderBtn {
        font-size: 13px;
        margin-left: 0px !important;
    }

   

}





/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {






}