
   
   
   
   
   .language-select{
        height: 35px;
       width: 150px; 
       color: black;
   }
   
   
   
   .adminLogoHeight{
    height: 60px;
    margin-left:55px
}