.WelcomeLogin {
  /* background: url('../../Assets/images/welome-login.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #a71722;
  /* padding-top: 120px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.ProfImage {
  width: 200px !important;
}

.WelcomeLogin p {
  font-size: 16px;
  margin-bottom: 1px;
  ;
  color: #fff;
}

.WelcomeLogin h2 {
  font-weight: bold;
  font-size: 25px;
  color: #fff;
}

.WelcomeLogin h3 {
  font-size: 14px;
  color: #fff;
}

.WelcomeLogin span {
  font-size: 20px;
  color: #fff;
}

.LogInContainerHr {
  border-color: #efebeb !important;
  width: 35%;
  margin-right: 64%;
}

.LoginMain {
  display: flex;
  flex-direction: column;
}

.LoginMainHeading {
  font-size: 18px;
  font-weight: 600;
  color: #4A4A4A;
}

.LoginMainHeadingHr {
  border-color: rgb(235, 234, 234);
}

.BtnFacebook {
  width: 100%;
  height: 45px;
  border: 0px;
  font-size: 16px
}

.BtnGoogle {
  width: 100%;
  height: 45px;
  border: 0px;
  font-size: 16px
}

.LogoHeight {
  height: 20px;
  float: left;
  margin-left: 5%;
  margin-top: 1%;
}

.LoginText {
  float: left;
  margin-left: 10%;
}

.LoginInputEmail {
  width: 100%;
  margin-bottom: 10px;
  height: 45px;
  background-color: #fff;
  border: 1px solid rgb(228, 228, 228);
}

.LoginInputPassword {
  width: 100%;
  margin-bottom: 10px;
  height: 45px;
  background-color: #fff;
  border: 1px solid rgb(228, 228, 228);
}

.LoginInputEmail {
  background: #FAFAFA url(../../Assets/images/mail.png) no-repeat scroll 10px 15px;
  padding-left: 40px;
}

.LoginInputPassword {
  background: #FAFAFA url(../../Assets/images/Password.png) no-repeat scroll 10px 15px;
  padding-left: 40px;
}

.LoginBtn {
  width: 100%;
  height: 45px;
  border: 1px solid #c01825;
  background-color: #c01825;
  color: #fff;
}

.ForgetPassword {
  width: 100%;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  color: #0093D9;
}

.DontHaveAccount {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #4A4A4A;
}

.DontHaveAccountSignUp {
  color: #0093D9;
}

.ForgetPasswordDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5%;
}

.ForgetPasswordText {
  font-size: 18px;
  color: #4A4A4A;
  text-align: center;
}

.ForgetPasswordInput {
  width: 80%;
  margin: 5%;
  padding-left: 10%;
  height: 45px;
  border: 1px solid rgb(228, 228, 228);
  background-color: #fff;
  background: #FAFAFA url(../../Assets/images/mail.png) no-repeat scroll 10px 15px;
}

.ForgetPasswordLoginBtn {
  width: 80%;
  margin: 5%;
  height: 45px;
  border: 1px solid #c01825;
  background-color: #c01825;
  color: #fff;
}

.ForgetPasswordEnterMail {
  margin-top: 20px;
  font-size: 14px;
  color: #4A4A4A;
  margin-left: 5%;
}

.OneTimePasswordDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5%;
}

.OneTimePasswordHeading {
  font-size: 18px;
  color: #4A4A4A;
  text-align: center;
}

.OtpInput {
  width: 80%;
  margin: 1% 0% 2% 10%;
  height: 45px;
  border: 1px solid rgb(228, 228, 228);
  background-color: "#fff"
}

.OtpBtn {
  width: 80%;
  margin: 5%;
  height: 45px;
  border: 1px solid #c01825;
  background-color: #c01825;
  color: #fff;
}

.OtpSetPassword {
  margin-top: 20px;
  font-size: 14px;
  color: #4A4A4A;
  margin-left: 5%;
}

.ClassesSubMenuContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: whitesmoke;
  z-index: 9999;
  padding: 20px 30px;
  top: 0px !important;
  border: 2px solid #b9b9b9;
  animation-name: homeHeader_examMenuAnimate__1l5DF;
  animation-duration: 0.1s;
}

.ClassesSubMenu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  height: 500px;
  align-items: flex-start;
  padding: 20px 5%;
  border-radius: 5px;
}

.RowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.CrossDiv {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  padding-top: 6px;
  flex-direction: row;
  align-items: flex-start;
}

.ClassesMenuLogo {
  width: 1085px;
  display: flex;
  justify-content: center;
  margin-top: 9px;
}

.CrossImg {
  width: 25px;
  height: 25px;
  cursor: pointer
}

.LogoImage {
  width: 120px;
}

@media(max-width:768px) {
  .MainLogin {
    flex-direction: column;
  }
  .LogoHeight {
    height: 15px;
    margin-top: 2%;
  }
  .WelcomeLogin {
    align-items: flex-start;
  }
  .ProfImage {
    width: 100px !important;
  }
  .BtnFacebook {
    margin-bottom: 1px !important;
    margin-top: 5px !important;
  }
  .BtnGoogle {
    margin-bottom: 10px !important;
  }
}

@media(max-width:576px) {
  .MainLogin {
    flex-direction: column;
  }
  .LogoHeight {
    height: 15px;
    margin-top: 2%;
  }
  .WelcomeLogin {
    align-items: flex-start;
  }
  .ProfImage {
    width: 100px !important;
  }
  .BtnFacebook {
    margin-bottom: 1px !important;
    margin-top: 5px !important;
  }
  .BtnGoogle {
    margin-bottom: 10px !important;
  }
}