@media screen and (min-width: 900px){

    .picky__dropdown,.option
 {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 100%;
        position: relative !important;
        top: 0;
        left: 0;
        z-index: 99;
        background: white;
        border:none !important;
        top: 1px !important;
       
    }
    .picky__input{
        display: none;
    }

    .btnam{
        padding:3% 5% 3% 5%;
        box-shadow: #E5E5E5 1px 2px 0px 0px;
        font-size:13px;
        margin-top:4%;
        float:right;
        background-color:#FAFAFA;
        border-radius:5px;
        border:1px solid #E5E5E5;
    }
    .font-family{
       
        font-family: sans-serif;
    }
    .menuNavbar{
        background-color: #f1f1f1;
        color: white;
        height: 8vh;
    }
    .menuDiv{
        padding:10px;
        width: 40vw;
        margin: 2% 4%;
        background-color: #f1f1f1;
        cursor: grabbing;
        box-shadow: gray 0px 0px 1px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
        
    }
    .selectedMenuDiv{
        padding:10px;
        width: 40vw;
        margin: 2% 4%;
        background-color: #3A7FCA;
        color: white;
        cursor: grabbing;
        box-shadow: gray 1px 1px 7px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
    }
    .menuData{
        display: flex;
        align-items: center;
        margin-bottom: 0
    }
    .sub-item{
        padding:10px;
        width: 20vw;
        margin: 2%;
        cursor: grabbing;
        box-shadow: gray 1px 1px 7px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }
    .admin_btn{
        background-color:#3A7FCA !important;
        height: 6vh ;
        line-height: 0.5px !important;
        display: flex;
        align-items: center;
        font-size: unset;
        min-width:120px;
        justify-content: center
    }
    .menu_data_div{
        margin: 0 2%;
        height: 29vw;
        font-size: unset
    }
    .edit_btn_div{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 45%;
        cursor: pointer;
    }
    .btn{
        margin: 6px
    }
    .add_btn{
        background-color:#3A7FCA !important;
        height: 5vh ;
        line-height: 0.5px !important;
        margin: 2% ;
        font-size: unset;
        width: 110px;
        border-radius: 5px
    }
    .labelPadding{
        padding: 5px 0 0 0;
    }
    .headingFontSize{
        font-size: 28px;
        font-weight: bold
    }
    .textFontSize{
        font-size: 14px
    }

    }
@media screen and (min-width: 1100px){

    .picky__dropdown,.option {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 100%;
        position: relative !important;
        top: 0;
        left: 0;
        z-index: 99;
        background: white;
        border:none !important;
        top: 1px !important;
       
    }
    .picky__input{
        display: none;
    }
    .btnam{
        padding:3% 5% 3% 5%;
        box-shadow: #E5E5E5 1px 2px 0px 0px;
        font-size:13px;
        margin-top:4%;
        float:right;
        background-color:#FAFAFA;
        border-radius:5px;
        border:1px solid #E5E5E5;
    }

    .font-family{
       
        font-family: sans-serif;
    }
    .menuNavbar{
        background-color: #f1f1f1;
        color: white;
        height: 8vh;
    }
    .menuDiv{
        padding:10px;
        width: 40vw;
        margin: 2% 4%;
        background-color: #f1f1f1;
        cursor: grabbing;
        box-shadow: gray 0px 0px 1px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
        
    }
    .selectedMenuDiv{
        padding:10px;
        width: 40vw;
        margin: 2% 4%;
        background-color: #3A7FCA;
        color: white;
        cursor: grabbing;
        box-shadow: gray 1px 1px 7px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
    }
    .menuData{
        display: flex;
        align-items: center;
        margin-bottom: 0
    }
    .sub-item{
        padding:10px;
        width: 20vw;
        margin: 2%;
        cursor: grabbing;
        box-shadow: gray 1px 1px 7px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }
    .admin_btn{
        background-color:#3A7FCA !important;
        height: 6vh ;
        line-height: 0.5px !important;
        display: flex;
        align-items: center;
        font-size: unset;
        min-width:120px;
        justify-content: center
    }
    .menu_data_div{
        margin: 0 2%;
        height: 25vw;
        font-size: unset
    }
    .edit_btn_div{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 45%;
        cursor: pointer;
    }
    .btn{
        margin: 6px
    }
    .add_btn{
        background-color:#3A7FCA !important;
        height: 5vh ;
        line-height: 0.5px !important;
        margin: 2% 2% ;
        font-size: unset;
        width: 120px;
        border-radius: 5px
    }
    .labelPadding{
        padding: 5px 0 0 0;
    }
    .headingFontSize{
        font-size: 28px;
        font-weight: bold
    }
    .textFontSize{
        font-size: 14px
    }

    }

    @media only screen and (min-width: 1300px)  and (min-height:525px){  
        
        .menuPageItems{
            padding: 10px;
            width: 18vw;
            margin: 0% 0%;
            background-color: white;
            cursor: pointer;
            box-shadow: grey 0px 0px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: unset;
        }
        .menuPageItems:hover{
            background-color: #F5F5F5;
        }
        .selectedMenuPageItems{
            padding: 10px;
            width: 18vw;
            margin: 0% 0%;
            background-color: #F5F5F5;
            cursor: pointer;
            box-shadow: grey 0px 0px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: unset;
        }
        .menuPageItemArrow{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: auto;
            cursor: pointer
        }
         .navbar{
             box-shadow: none
         }  
         
        .menuDiv:hover{
            box-shadow: gray 0px 0px 1px 0px;
        }
        
        .picky__dropdown,.option {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            width: 100%;
            position: relative !important;
            top: 0;
            left: 0;
            z-index: 99;
            background: #FAFAFA!important;
            background-color: #FAFAFA!important;
            border:none !important;
        
            top: 1px !important;
            
        }
        .picky__input{
            display: none;
        }
        
        .btnam{
            padding:3% 5% 3% 5%;
            box-shadow: #E5E5E5 1px 2px 0px 0px;
            font-size:13px;
            margin-top:4%;
            float:right;
            background-color:#FAFAFA;
            border-radius:5px;
            border:1px solid #E5E5E5;
        }
        
        
         /* --------------------------------------------------------------- */
            .font-family{
            
            font-family: sans-serif;
        }
        .menuNavbar{
            background-color: #F5F5F5;
            color: white;
            height: 8vh;
        }
        .menuDiv{
            padding:10px;
            width: 30vw;
            margin: 2% 4%;
            background-color: #FAFAFA;
            cursor: grabbing;
            box-shadow:#E5E5E5 0px 0px 0px 1px;
            border: 1px solid #E5E5E5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: unset
            
        }
        .selectedMenuDiv{
            padding:10px;
            width: 30vw;
            margin: 2% 4%;
            background-color: #FAFAFA;
            color:black;
            cursor: grabbing;
            box-shadow: gray 1px 1px 3px 1px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: unset
        }
        .menuData{
            display: flex;
            align-items: center;
            margin-bottom: 0
        }
        .sub-item{
            padding:10px;
            width: 20vw;
            margin: 2%;
            cursor: grabbing;
            box-shadow: gray 0px 1px 3px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center
        }
        .admin_btn{
            background-color:#008EC2 !important;
            height: 6vh ;
            line-height: 0.5px !important;
            display: flex;
            align-items: center;
            font-size: unset;
            min-width:132px;
            border: 1px solid #006799;
            justify-content: center;
            border-radius: 5px
        }
        .menu_data_div{
            margin: 0 2%;
            height: 22vw;
            font-size: unset
        }
        .edit_btn_div{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 45%;
            cursor: pointer;
        }
        .btn{
            margin: 6px
        }
        .add_btn{
            background-color:#008EC2 !important;
            color: white !important;
            height: 6vh ;
            line-height: 0.5px !important;
            margin: 2% 2% ;
            font-size: unset;
            width: 120px;
            border: 1px solid #006799;
            border-radius: 5px
        }
        .labelPadding{
            padding: 5px 0 0 0;
        }
        .headingFontSize{
            font-size: 20px;
            font-weight: bold
        }
        .textFontSize{
            font-size: 14px
        }
        

    }  
   
   
   
   
   
   @media only screen and (min-width: 1300px)  and (min-height:668px){   
       
    .menuPageItems{
        padding: 10px;
        width: 18vw;
        margin: 0% 0%;
        background-color: white;
        cursor: pointer;
        box-shadow: grey 0px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset;
    }
    .menuPageItems:hover{
        background-color: #F5F5F5;
    }
    .selectedMenuPageItems{
        padding: 10px;
        width: 18vw;
        margin: 0% 0%;
        background-color: #F5F5F5;
        cursor: pointer;
        box-shadow: grey 0px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset;
    }
    .menuPageItemArrow{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: auto;
        cursor: pointer
    }
     .navbar{
         box-shadow: none
     }  
     
    .menuDiv:hover{
        box-shadow: gray 0px 0px 1px 0px;
    }
    
    .picky__dropdown,.option {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 100%;
        position: relative !important;
        top: 0;
        left: 0;
        z-index: 99;
        background: #eee;
        background-color: #eee;

        border:none !important;
        border-bottom: none !important ;
        
        top: 1px ;
     
    }
    .picky__input{
        display: none;
    }
    .btnam{
        padding:5%;
          
        box-shadow: #E5E5E5 1px 2px 0px 0px;
        font-size:13px;
        margin-top:4%;
        float:right;
        background-color:#FAFAFA;
        border-radius:5px;
        border:1px solid #E5E5E5;
    }



    
    
     /* --------------------------------------------------------------- */
        .font-family{
        
        font-family: sans-serif;
    }
    .menuNavbar{
        background-color: #F5F5F5;
        color: white;
        height: 8vh;
    }
    .menuDiv{
        padding:10px;
        width: 30vw;
        margin: 2% 4%;
        background-color: #FAFAFA;
        cursor: grabbing;
        box-shadow:#E5E5E5 0px 0px 0px 1px;
        border: 1px solid #E5E5E5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
        
    }
    .selectedMenuDiv{
        padding:10px;
        width: 30vw;
        margin: 2% 4%;
        background-color: #FAFAFA;
        color:black;
        cursor: grabbing;
        box-shadow: gray 1px 1px 3px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: unset
    }
    .menuData{
        display: flex;
        align-items: center;
        margin-bottom: 0
    }
    .sub-item{
        padding:10px;
        width: 20vw;
        margin: 2%;
        cursor: grabbing;
        box-shadow: gray 0px 1px 3px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }
    .admin_btn{
        background-color:#008EC2 !important;
        height: 6vh ;
        line-height: 0.5px !important;
        display: flex;
        align-items: center;
        font-size: unset;
        min-width:132px;
        border: 1px solid #006799;
        justify-content: center;
        border-radius: 5px
    }
    .menu_data_div{
        margin: 0 2%;
        height: 22vw;
        font-size: unset
    }
    .edit_btn_div{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 45%;
        cursor: pointer;
    }
    .btn{
        margin: 6px
    }
    .add_btn{
        background-color:#008EC2 !important;
        color: white !important;
        height: 6vh ;
        line-height: 0.5px !important;
        margin: 2% 2% ;
        font-size: unset;
        width: 120px;
        border: 1px solid #006799;
        border-radius: 5px
    }
    .labelPadding{
        padding: 5px 0 0 0;
    }
    .headingFontSize{
        font-size: 20px;
        font-weight: bold
    }
    .textFontSize{
        font-size: 14px
    }
 


     }


@media screen and (min-width: 1800px){

        .picky__dropdown,.option
 {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 100%;
        position: relative !important;
        top: 0;
        left: 0;
        z-index: 99;
        background: white;
        border:none !important;
        top: 1px !important;
    }
    .picky__input{
        display: none;
    }
    .btnam{
        padding:3% 5% 3% 5%;
        box-shadow: #E5E5E5 1px 2px 0px 0px;
        font-size:13px;
        margin-top:4%;
        float:right;
        background-color:#FAFAFA;
        border-radius:5px;
        border:1px solid #E5E5E5;
    }
.font-family{
    
    font-family: sans-serif;
}
.menuNavbar{
    background-color: #f1f1f1;
    color: white;
    height: 8vh;
}
.menuDiv{
    padding:10px;
    width: 40vw;
    margin: 2% 4%;
    background-color: #f1f1f1;
    cursor: grabbing;
    box-shadow: gray 0px 0px 1px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-large
    
}
.selectedMenuDiv{
    padding:10px;
    width: 40vw;
    margin: 2% 4%;
    background-color: #3A7FCA;
    color: white;
    cursor: grabbing;
    box-shadow: gray 1px 1px 7px 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-large
}
.menuData{
    display: flex;
    align-items: center;
    margin-bottom: 0
}
.sub-item{
    padding:10px;
    width: 20vw;
    margin: 2%;
    cursor: grabbing;
    box-shadow: gray 1px 1px 7px 1px;
    display: flex;
    justify-content: space-between;
    align-items: center
}
.admin_btn{
    background-color:#3A7FCA !important;
    height: 6vh ;
    line-height: 0.5px !important;
    display: flex;
    align-items: center;
    font-size: x-large;
    min-width:180px;
    justify-content: center
}
.menu_data_div{
    margin: 0 2%;
    height: 22vw;
    font-size: x-large
}
.edit_btn_div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40%;
    cursor: pointer;
}
.btn{
    margin: 6px
}
.add_btn{
    background-color:#3A7FCA !important;
    height: 5vh ;
    line-height: 0.5px !important;
    margin: 2% 2%;
    font-size: x-large;
    border-radius: 5px
}
.labelPadding{
    padding: 5px 0 0 0;
}
.headingFontSize{
    font-size: 40px;
    font-weight: bold
}
.textFontSize{
    font-size: x-large
}

}