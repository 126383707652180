.LoginMobileHeader{


    display:none;
}

.LoginHeader{

    display: block;
}
.LessonMobileHeader{
    display: none;
}
.contactlogout{
    opacity: 0;
    
}
.MenuPart{

background-color: rgb(247, 242, 242);

    clip-path: polygon(12% 0, 100% 0%, 100% 100%, 0% 100%);
    height: 135px;
}



.LoginHeader {
    padding: 0px 0px 0px 80px;

}
.MenuPartPadding{
    padding-right: 80px;

}

.LogoPart{

    height: 120px;
}


.LoginHeaderTopPart{
    display: flex;
    justify-content: flex-end;

    padding-right: 71.5px;
 

}
.LoginHeaderBottomPart{
    padding-right: 80px;

}

.LoginHeaderBtnSearch {
    flex-direction: column;
}

.InputHeaderSearchDiv {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 40px;
    position: relative
}

.InputHeaderSearch {
    width: 100%;
    height: 40px;
    padding-left: 10px;
}


.SelectRating{
    font-family: 'OpenSans-B';
    text-align: center;
}
.SearchIcon {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    /* margin-top: -30px; */
    position: absolute;
    right: 0;
    width: 25px;
    top: 25%;

}

.HeaderRedBtn {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #fff;
    height: 40px;
    width: 100px;

    font-size: 16px;
}

.HeaderLangBtn {
    background-color: #fff;
    border: 1px solid #afafaf;
    color: #4A4A4A;
    height: 40px;
    width: 100px;
    font-size: 16px;
}
    

.HeaderLogInBtn {
    background-color: #fff;
    border: 1px solid #afafaf;
    color: #4A4A4A;
    height: 40px;
    width: 100px;
    font-family: OpenSans-R;
    font-size: 16px;
}

.HeaderSignUpBtn {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: #fff;
    height: 40px;
    width: 100px;
    font-family: OpenSans-R;
    font-size: 16px;
}
.ProfileMenuList:hover{
    color: #c01825;
}


.HrTag {
    background-color: #c01825;
    margin: 1px 0px;
}

.HrTagTop {
    background-color: #c01825;
    margin: 0px 0px 1px 0px;
}


.LoginHeaderMenus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 25px;



}

.HeaderSocialMedia {
    height: 40px;
    width: 40px;
    margin-right: 5px;
    border: 1px solid #afafaf;
    background-color: transparent;
}

.HeaderSocialMedia:hover {
    background-color: #c01825;
    border: 1px solid #c01825;
    color: white;
}
.ProfileMenuList{
        font-weight: 600;
    font-family: OpenSans-R;
    font-size: 16px;
    margin-right: 16px;
    color: #444444;
    cursor: pointer;
}
.activeLink{
    color:#c01825;
}

.ProfileMenuListContact{

    font-family: OpenSans-R;
    font-size: 16px;
    /* margin-right: 16px; */
    cursor: pointer;
    background-color: #c01825;
    padding: 3px 20px;
    border: 1px solid #c01825;

    color: white;
    /* margin-top: -5px; */

}

.HeaderIconsDiv{

    height: 40px;
    width: 40px;

    background-color: transparent;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.HeaderIconsDiv:hover{

 
    width: 40px;
    border: 1px solid #bbb;
    background-color: #fff;
    color: #c01825;
   

}

.HeaderSocialMediaDiv{
    display: flex;
}

.HeaderSocialMediaHover{
    display: none;
    height: 40px;
    width: 40px;
    margin-right: 5px;
    border: 1px solid black;
    background-color: transparent;

}


.HeaderProfilePic{

    height: 40px;
    width: 40px;
    border: 1px solid #bbb;

    border-radius: 50%;

}

.HeaderIcons{
    height: 16px;
    /* width: 20px; */
}

.HeaderToggleMenu{
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-top: 1px solid #e8e9eb;
    box-shadow: 0 4px 16px rgba(20,23,28,.25);
    color: #505763;
    font-size: 14px;
    margin: 0;
    min-height: 30vh;
    z-index:9999;
    background-color: white;
    min-width: 245px;
    right: 0%;
    display: flex;
    flex-direction: column;
   position: absolute;
    padding-top: 20px;
    font-weight: 400;
    margin:55px;
    top:85px
}
.HeadsetLogo{
    margin-left: 5px;
    font-size: 14px;
}

.HeaderToggleMenu:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  .HeaderToggleMenu:before {
    right: 19px;
    border: 11px solid transparent;
    border-bottom-color: #fff;
  }
  .HeaderToggleMenu:after {
    left: 20px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }
.ToggleRow{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    cursor: pointer;
}
.ProfileMenuListToggle{
    font-family: OpenSans-R;
    font-size: 14px;
    padding-left: 25px;
    cursor: pointer;
    margin: 0px;
    padding: 10px 0px 10px 25px;
}







.ProfileMenuListToggle:hover{
background-color: rgb(247, 242, 242);

color: black;
}

.ClassesSubMenuContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: whitesmoke;
    z-index: 9999;
    padding: 20px 30px;
    top: 0px !important;
    /* border-radius: 10px; */
    border: 2px solid #b9b9b9;
    /* margin: 0px 10%; */
    animation-name: homeHeader_examMenuAnimate__1l5DF;
    animation-duration: 0.1s;
}

.ClassesSubMenu{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    height: 500px;
    align-items: flex-start;
    padding: 20px 5%;
    border-radius: 5px;
}
.RowReverse {
    display: flex;
    flex-direction: row-reverse;
}

.CrossDiv {
    display: flex;
    justify-content: flex-end;
    width: 10%;
    padding-top: 6px;
    flex-direction: row;
    align-items: flex-start;
}

.ClassesMenuLogo{
    width: 1085px;
    display: flex;
    justify-content: center;
    margin-top: 9px;
}

.CrossImg{
    width: 25px;
    height: 25px;
    cursor: pointer
}

.LogoImage {
    width: 120px;
}
@media(max-width:1024px)   {
    .ProfileMenuList{
                
        font-family: OpenSans-R;
        font-size: 14px;
        margin-right: 5px;
    
    }

    .HeaderEnglishBtn {
        
        font-family: OpenSans-R;
        font-size: 12px;
    }
    
    .HeaderHindiBtn {
    
        font-family: OpenSans-R;
        font-size: 12px;
    }
    
    .HeaderLogInBtn {

        font-family: OpenSans-R;
        font-size: 12px;
    }
    
    .HeaderSignUpBtn {
     
        font-family: OpenSans-R;
        font-size: 12px;
    }
    
    .HeaderSocialMedia {
        height: 40px;
        width: 40px;
        font-size: 14px;
        margin-right: 5px;
    }


    .InputHeaderSearchDiv {
        display: flex;
        flex-direction: column;
    ;
    }
.LoginLogo{

    width:130px ;
}




.LoginHeaderBottomPart{
    padding-right: 40px;

}

.LoginHeaderTopPart {
    padding-right: 40px;
}



.LoginHeader {
    padding: 0px 0px 0px 40px
  
}

}

@media(max-width:769px)  {

    .LoginLogo{
        height:auto ;
        width: auto;
    }
.LoginMobileHeader{

    display: block;
}

.LoginHeader{

    display:none;
}

.LessonMobileHeader{
    display: block;
}


}

@media(max-width:576px)   {}



@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .HeaderRedBtn {
        height: 40px;
        width: 100px !important;
        font-size: 13px;
    }
    .HeaderBtn {
        font-size: 13px;
        margin-left: 0px !important;
    }

    .InputHeaderSearchDiv {

        width: 25%;
      
    }
    .ContentCourseImage {
 
        height: 75px;
    }


    .ProfileMenuList {
        
        margin-right: 12px;
    }


    .ProfileMenuListContact {
      
        height: 30px;
        /* margin-top: -5px; */
    }

    .HeaderIconsDiv{

    margin: 0px !important;
    }



}





/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {






}