




.NoSpinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}


  .RegistrationBackground{
    background:  url('../../Assets/images/Sign-bg.png') ; 
 
    background-repeat: no-repeat;
    background-size: 100% 100%;
 
padding: 0px 80px;

  }

  

    .MainRegistration{
       
        /* background-color: #be1725 ; */
        background-color: rgba(190,23,37,0.8);
        border: 1px solid #fff


    }

.MainRegistration h2 {

  color: #fff;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.MainRegistration p {

  color: #fff;
  font-size: 14px;
  text-align: left;
}


.LableText{

  color: #fff;
  font-size: 15px;

}
  .RegistrationInputType{

    height: 45px;
    background-color: transparent;
    border:1px solid #fff;
    color:#fff ;

  }

  .RegistrationInputType :focus{

    border:1px solid #fff;
    color:#fff ;

  }
  .RegistrationInputType::placeholder {
    color: rgb(211, 211, 211);
    font-size: 16px;
  }
  .RegistrationInputTypeList::placeholder {
    color: rgb(211, 211, 211);
    font-size: 16px;
  }

  .RegistrationInputTypeState::placeholder {
    color: rgb(211, 211, 211);
    font-size: 16px;
  }




  .RegistrationInputTypeList{
    height: 45px;
    background-color: #fff;
    border:1px solid #fff;
    color:#4A4A4A ;
    background:#FAFAFA url(../../Assets/images/down.png)  97% 50% no-repeat ;


  }



  .RegistrationInputTypeState{
    height: 45px;
    background-color: #fff;
    border:1px solid #fff;
    color:#4A4A4A ;
   


  }




  .RegistrationInputTypeList::-webkit-calendar-picker-indicator {
    opacity: 0;
    }

    .RegistrationInputTypeState::-webkit-calendar-picker-indicator {
      opacity: 0;
      }
  

    .RegistrationInputTypeList::-webkit-calendar-picker-indicator {
      opacity: 0;
      }
      .RegistrationInputTypeState::-webkit-calendar-picker-indicator {
        opacity: 0;
        }

    .RegistrationInputTypeDate{
      width: 75% !important;
    }
   .SignUpDate > div{
     width: 75%;

   }
   .SignUpDate > div > div > div{
  
    border-radius: 0px;
  }
  .SignUpDate > div > div > div >{
  
  /* display: none; */
  }



  .SignUPContainerHr{
    border-color: #fff !important;
   
}


.CustomFileUpload {
  border: 1px solid black;
display: inline-block;
padding: 2% 0% 2% 0%;
text-align: center;
cursor: pointer;
background-color: black;
color: #ffffff;


height: 45px;
width: 150px;
}

.CustomFileInput{

  display: none;
}

.SignUPAcceptAll{

  margin-left: 10px;
  color: #fff;
  font-size: 16px;
}

.RegistrationTextDiv{

  margin-top: 40px;
  justify-content: space-between;
}

.RegistrationText{


  color: #fff;
  font-size: 20px;
}

.RegistrationTextLogIn{

  color: #fff;
  font-size: 18px;
  font-weight: bold;


}

.FooterBottom{

  
}

.FileSizeLimit{
  font-size: 14px;

  color: #fff;
}

.BtnReset{

  border: 1px solid black;

  background-color: black;
  color: #ffffff;
  width: 150px;
  height: 40px;
  font-size: 14px;



}
.BtnConfirm{
  border: 1px solid #ffffff;

  background-color: #ffffff;
  color: black;
  width: 150px;
  height: 40px;
  margin-left: 20px;
  font-size: 14px;

}

@media(max-width:1024px)   {
  
  }

@media(max-width:768px)   {
.SignUPForm{

  flex-direction: column;
}
.RegistrationTextDiv{
margin-top: 10px;
 flex-direction: column;
}

.RegistrationBackground{

  background:  url('../../Assets/images/Sign-bg.png') ; 
 
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 20px;

}

.MainRegistration{
       
  background-color: rgba(190,23,37,0.8);
  border: 1px solid #fff



}


.SignUPContainerHr{
display: none;
 
}


}


@media(max-width:576px)   {

  
.RegistrationBackground{
  background:  none;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px;

}


  .SignUpDate > div{
    width: 75%;
    border: 1px solid #4A4A4A;

  }
  .MainRegistration {
    color:#4A4A4A;
    background-color: rgb(238, 234, 234);
    margin-top: 50px;
 
  }


  .MainRegistration h2 {
    color:#4A4A4A;
    font-size: 20px;

  }
  
  .MainRegistration p {
    color:#4A4A4A;
    font-size: 14px;

  }
  .LableText{
    font-size: 12px;
    color:#4A4A4A;
  }

  .RegistrationInputType{
    color:#4A4A4A;
    height: 35px;
    border:1px solid #4A4A4A
   

  }
  .RegistrationInputTypeList{
    height: 35px;
       color:#4A4A4A;
       border:1px solid #4A4A4A
  }
  .RegistrationInputTypeState{

    height: 35px;
    color:#4A4A4A;
    border:1px solid #4A4A4A

  }


  .FileSizeLimit{
    font-size: 10px;
    color:#4A4A4A;
 
  }

  .CustomFileUpload {
    border: 1px solid black;
  display: inline-block;
  /* padding: 2% 0% 2% 0%; */
  text-align: center;
  cursor: pointer;
  background-color: black;
  color: #ffffff;
  height: 35px;
  font-size: 14px;
  width: 100px;
  }
  .BtnReset{

    border: 1px solid black;
 
    background-color: black;
    color: #ffffff;
    height: 35px;
    font-size: 14px;
    width: 100px;
  
  
  
  }
  .BtnConfirm{
    border: 1px solid black;

    background-color: #ffffff;
    color: black;
    height: 35px;
    font-size: 14px;
    width: 100px;
    margin-left: 20px;

  
  }

  .RegistrationInputType::placeholder {
    color:#4A4A4A;
    font-size: 14px;
  }
  .RegistrationInputTypeList::placeholder {
    color:#4A4A4A;
    font-size: 14px;
  }


  .RegistrationInputTypeState::placeholder {
    color:#4A4A4A;
    font-size: 14px;
  }

  .SignUPAcceptAll{
    color:#4A4A4A;
    font-size: 14px;
  }

  .RegistrationText{

    color:#4A4A4A;
    font-size: 16px;
  }
  
  .RegistrationTextLogIn{
    color:#4A4A4A;
    font-size: 14px;

  
  
  }
  

}


