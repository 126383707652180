.CardTitleOtherService{
    text-align: center;
    font-size: 15px;
    margin-top: 15px;
    font-weight: bold;
    font-family: 'OpenSans-R';
}

.ContentCourseOtherService{
    display: flex;
    flex-direction: column;
height: 300px;
max-height: 550;
    border: 1px solid #c01825;
}
.otherservicerow{
}