@font-face {
    font-family: 'OpenSans-R';
    src: url('../../Assets/Fonts/OpenSans-Regular_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-B';
    src: url('../../Assets/Fonts/OpenSans-Bold_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-I';
    src: url('../../Assets/Fonts/OpenSans-Italic_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-L';
    src: url('../../Assets/Fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.FooterContainer {
    background-color: #f5f5f5;
    padding: 0 80px 0 80px;
}

.FooterContainer h3 {
    /* font-family: Ubuntu-B; */
    font-size: 18px;
    color: #666666;
}

.FooterContainer ul {
    list-style: none;
    padding-left: 0px;
    color: #666666;
}

.FooterContainer ul li {
    /* font-family:Ubuntu-L; */
    font-size: 15px;
    line-height: 2.2;
    padding-left: 5px;
}

.FooterContainer ul li:hover {
    color: black;
}

.FooterContainerHr {
    border-color: #b4b4b4 !important;
    margin: 0px !important;
}

.HrTag {
    background-color: #c01825;
    margin: 2px;
}

.SendQueryInput {
    height: 35px;
    padding-left: 10px;
}

.SendQueryTextArea {
    height: 70px;
    padding-left: 10px;
}

.SendBtn {
    border: 1px solid #c01825;
    background-color: #c01825;
    height: 35px;
    color: #fff;
}

.FooterBottom p {
    font-size: 16px;
}

.FooterBottom sub {
    bottom: -0.15em;
    font-size: 20px;
}

.MobView {
    display: none;
}

.WindowView {
    display: block;
}

@media(max-width:1024px) {
    .FooterContainer h3 {
        font-size: 16px;
        color: #666666;
    }
    .FooterContainer ul {
        list-style: none;
        padding-left: 0px;
        color: #666666;
    }
    .FooterContainer ul li {
        font-size: 12px;
    }
    .SendQueryInput {
        height: 30px;
    }
    .SendQueryTextArea {
        height: 60px;
    }
}

@media(max-width:768px) {
    .MobView {
        display: block;
    }
    .WindowView {
        display: none;
    }
    .CardFooter {
        align-items: center;
        justify-content: center;
        background-color: #d6d5d5;
        color: black;
        border-right: 1px solid #ffff;
    }
    /* .CardFooter:hover{

    justify-content:center;
    background-color:black;
    color: white;
    border-right:1px solid black;
} */
    .InputContactSticky {
        margin: 5px;
        border-radius: 2px;
        border: 1px solid #4A4A4A
    }
    .BtnSendContactSticky {
        width: 75px;
        height: 30px;
        background-color: black;
        border: 1px solid black;
        text-align: center;
        color: #FFF0E9;
        /* margin-left: 5px; */
    }
    .DivContactSticky {
        flex-direction: column;
        margin-left: 20%;
        margin-right: 20%;
    }
    .HeadingContactSticky {
        width: 100%;
        padding-left: 30% !important;
    }
    .ModalContactSticky {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: cover;
        background-image: url('../../Assets/images/contact-background.jpg');
    }
}

@media(max-width:576px) {
    
    .MobView {
        display: block;
    }
    .WindowView {
        display: none;
    }
    .InputContactSticky {
        margin: 5px;
        border-radius: 2px;
        border: 1px solid #4A4A4A
    }
    .BtnSendContactSticky {
        width: 75px;
        height: 30px;
        background-color: black;
        border: 1px solid black;
        text-align: center;
        color: #FFF0E9;
    }
    .DivContactSticky {
        margin-left: 10%;
        margin-right: 10%;
    }
    .HeadingContactSticky {
        width: 100%;
        padding-left: 25% !important;
    }
}

input {
    font-size: 14px;
}

.activeStickyLink {
    color: white;
    background-color: black;
}