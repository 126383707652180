.add_product_main{
    width: 83%;
}
.add_product_heading{
    padding: 2% 2%;
    width: 50%;
    padding-bottom:0
}

.pmain{

    display: flex;

}

.ptabm{
    background-color: white;
    border: 1px solid #e5e5e5;
  

}
.ptabm h2 {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin-left: 2%;
    margin-top: 5%;
}
.ptab{


    display: flex;
    justify-content: center;
    width: 22vw;
   
}
#all-exams .mt-5,#all-exams .my-5 {
    margin-top: 0rem!important;
}


.product_add_title{
    padding: 3px 8px;
    font-size: 1.7em;
    line-height: 100%;
    height: 1.7em;
    width: 100%;
    outline: 0;
    margin: 0 0 3px;
    background-color: #fff;
}
.add_product_content{
    width: 90%;
    padding: 0% 2%;
}
.md-form.md-outline input[type=text], .md-form.md-outline input[type=password], .md-form.md-outline input[type=email], .md-form.md-outline input[type=url], .md-form.md-outline input[type=time], .md-form.md-outline input[type=date], .md-form.md-outline input[type=datetime-local], .md-form.md-outline input[type=tel], .md-form.md-outline input[type=number], .md-form.md-outline input[type=search-md], .md-form.md-outline input[type=search], .md-form.md-outline textarea.md-textarea {
    transition: all .3s;
    outline: none;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
}
.application_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2%;
}
.md-form.md-outline {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}
.addButton{
    cursor:pointer;
    font-weight:bold;
    padding: 0% 2%;
}
.add_applicaton_div{
    background-color: white;
    border: 1px solid #e5e5e5;
    
}
.product_app_heading{
    padding:1%;
    border-bottom:1px solid #d2d2d2
}

.pbtn{

    
        display: flex;
        justify-content: center;
    
}


.typeP{

    width: 30vw;
 
    margin-left: 25%;
}

.AddExamRowCol{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}