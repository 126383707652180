.isActive{
height: auto;
opacity: 1;
transition: all 0.3s ease-in;
}
.isInactive{
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-out;
}

.explaination{
    height: 110px;
    overflow-y: scroll;
}
strong{
    font-family: 'OpenSans-B';
}