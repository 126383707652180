
 .MobileHeader{
  position: fixed;
  width: 100%;

  z-index: 99;
  background-color: white;
  top:0;
  height:60px;
}
.HeaderIcons{
  height: 18px;
  color: #c73340;
  /* width: 20px; */
}

.HeaderSocialMediaDiv{
  padding: 10px 20px;

}


.HeaderSocialMedia {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}

.HeaderSocialMedia:active {
  background-color: #fff;
  border: 1px solid #fff;
  color: black;
}
.HeaderSocialMedia:focus {
  background-color: #fff;
  border: 1px solid #fff;
  color: black;
}

.MobileHeaderIconsContainer{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100px;
}

.MobileHeaderIconsContainer img{
  width:20px;
}
.MobileHeaderIconsContainer{
  position: relative;
}

.NewHeaderLogo{
  width:55px;
}

.ButtonMobileLogIn{
  width: 110px !important;
  height: 40px;
  background-color: transparent;
  border: 1px solid #fff !important;
  text-align: center !important;
  color: #fff;
  font-size: 14px !important;
  font-family: OpenSans-R;
  margin-left: 15px;
}



.ButtonMobileRegister{
  width: 110px !important;
  height: 40px;


  background-color: #fff !important;
  border: 1px solid #fff !important;
  text-align: center !important;
  color: #4A4A4A !important;
  font-size: 14px !important;
  font-family: OpenSans-R;
  margin-left: 25px;









}

.EmailTextProfilePicDiv{


  padding: 10px 20px !important;
    display: flex;
    flex-direction: row;
    align-items: center;

   color: white; 
   font-family: OpenSans-R;
    background-color: rgba(152, 48, 48,0.95);


}

.UserName{
font-size: 20px;
font-weight: 600;
text-transform: capitalize;



}

.UserMailId{


  font-size: 12px;
}

.LogOutHelpDiv{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 50%;
  margin-left: 7px;
  font-weight: 600;
}

.ProfileMenuList{
  color:#fff;
  padding: 10px 20px;
  font-family: OpenSans-R;
  text-align: left;
  font-size: 14px;
  margin: 0px;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;

}




.Avatar{
  width: 40px !important;
  height: 40px;
border: 1px solid #d2d2d2;
border-radius: 50px;
}


.NavBarDP{
  background-color: white !important;
}

 

.MobileSidebarExams{
  background-color: rgba(152, 48, 48,0.95);

  border: 0;
  font-size: 14px;
  justify-content: space-between;
  display: flex;
  text-align: left;
  color: #fff;
  align-items: center;
  padding: 0px 0px 10px 60px;
  cursor: pointer;
  font-family: OpenSans-R;
  margin: 0;
}



.MobileMdbBtn{  
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-family: OpenSans-R !important;
  text-align: left !important;
  font-size: 14px !important;
  color: #fff !important;
  /* margin: 0px !important; */
  margin-top:5px;
  transition:none !important;
  padding: 5px 20px !important;
 width: 100% !important;

}



.MobileMdbBtnColor{  
  background-color: rgba(152, 48, 48,0.95);

  box-shadow: none !important;
  font-family: OpenSans-R !important;
  text-align: left !important;
  font-size: 14px !important;
  color: #fff !important;
  margin: 0px !important;
  transition:none !important;
  padding: 10px 20px !important;
 width: 100% !important;

}


.CloseMobMenuButton{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  background-color: transparent;
  border: none;
  color: #ffffffa3;
}




.CloseMobMenuButton img{
  width:25px;
}


.ButtonMobileSideBar{
  display: flex;
  flex-direction: row;
 
  width: 100%;
  height: 85px;
  background-color: rgba(152, 48, 48,0.95);
  align-items: center;
}

.TogglePNG{

  width: 30px;
  height: 30px;
}


.HrTag {
  background-color: #ffffffa3;
  margin: 10px 0px;
  width: 100%;
}

.HrTagX {
  background-color: #e7a9ae;
  margin: 0px 0px 2px 0px;
}
.HrTagY {
  background-color: #e7a9ae;
  margin: 0px 0px 0px 0px;
}



@media(max-width:576px) {

.NewHeaderLogo{
  width:75px;
  margin-left: 15px;
}
.HeaderIcons{

  width: 18px;
}

}