[type="textP"]::-webkit-input-placeholder {
    text-align: center;
    opacity: 0.2;
    font-family: Ubuntu-R;
    
    font-size: 16px;
    }
    .test_cost_cart{
        width: 100%;
    text-align: end;

    }



  .invoice-table-head{
    background-color:#b7b7b7
  }  

  #invoice .table td{
      font-size:12px !important
  }
    [type="textP"]:focus::-webkit-input-placeholder {
        color:transparent;
       
        }
        .region_select{
            color:#495057;
            display: block;
            margin-top: 10px;
    width: 45% !important;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .edit_address_div{
            width:100%
        }
        .sign{
            width: 150px;
    margin-right: 20px;
        }
        .removePromoText{
            color: red;
            font-size: 12px;
            margin-top: 1px;
            cursor: pointer;
        }
        .sideInv{
   
            top: 0;
            bottom: 0;
            left: 0;
            width: 260px;
           
            z-index: 1;
            color: #fff;
            font-weight: 200;
            background-size: cover;
            background-position: center center;
            background-color: #1C2231;
            padding-top: 5px
        }
        .confirm{
            background-color: #004080 !important;
            color: #fff !important
        }
        .table td{
            border-top: 1px solid #dee2e6;
            font-size: 16px !important;
            font-family: inherit;
        
        }
        .dropmenu2{
            background-color: #004080 !important;
           visibility: visible
        }
        .payu{
            margin-left: 2px;
            margin-right: 2px;
            width: 150px;
            height: auto;
            box-shadow: 1px 1px 1px;
        }
        .dropdown-menu{
          
            visibility: visible !important
        }
        .invoiceRow{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column
        }
        .upload-btn-wrapper {
            position: relative;
        
            display: inline-block;
          }
          
          .btn {
            border: 2px solid gray;
            color: white;
            background-color: white;
            padding: 10px;
            border-radius: 5px;
            cursor:'pointer';
            font-weight: bold;
          }
          .btn2 {
            border: 1px solid gray;
            color: white;
            background-color: #004080;
            padding: 10px;
            border-radius: 5px;
            cursor:'pointer';
            font-weight: bold;
            margin-right:15px
          }
          
          .upload-btn-wrapper input[type=file] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
          .bodyDivInv{
            display: flex;
         height: fit-content;
         
           
        }
        
        .viewPromoText{
            font-size: 15px;
            font-family: Ubuntu-R;
            font-weight: 600;
            color: #C01825 !important;
            padding-right: 5px;
           
          cursor: pointer;
          transition: font-size 0.3s ease-in-out;
        }
        .viewPromoText:hover{
          
          font-size: 17px;
          transition: font-size 0.3s ease-in-out;
        }
@media only screen and (min-width: 320px)   {
   
   

}
@media only screen and (min-width: 900px)   {


    .myCartMain{
    width: 100%;
    /*display: flex;
    flex-direction: row;
*/
    margin-left: 5%;
    margin-right: 5%;
    background-color: #F4F4F4 !important;
    }

    /* .myCartAccountNav{

        width: 30%;
        background-color: #FFF !important;
      
    
    

    } */
    
    .RegistrationButton1{
        display: flex;
        margin-right: 2%;
        font-size: 16px;
    }
    .RegistrationButton2{
        display: flex;
        font-size: 16px;
    }

    .ButtonReset{
        width: 160px;
        height: 40px;
        border-radius: 5px;
        background-color: #C01825;
        border: 1px solid #C01825;
        text-align: center;
        color: #FFF0E9;

    }

    .ButtonReset:hover {
 
      box-shadow: 0 5px 15px #C01825;
    }
    .ButtonConfirm{
        width: 160px;
        height: 40px;
        border-radius: 5px;
        background-color: #0093D9;
        border: 1px solid #0093D9;
        text-align: center;
        color: #FFF0E9;
    }

    .ButtonConfirm:hover {
 
      box-shadow: 0 5px 15px #0093D9;
    }

    .myCartDetail{

        width: 60%;
        display: flex;
        background-color: #FFF !important;
        flex-direction: column;
        padding: 2%;

    

    }

    .myCourses{

        width: 100% !important;
        display: flex;
        flex-direction: row;
        align-items: start;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: 2% 0% 2% 0% !important;
        z-index: 0;
        padding-top:10px;
        border-radius: 5px;

    

    }

    .myCoursesImg{

        width: 10%;
        height: 100%;
        padding: 2px;
        border-radius: 5px;
       display: flex;
       align-items: center;
       
        
    }
    

    .myCoursesNameAndDel{
        width: 42%;
        height: 100%;
        padding: 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 2%;
    }
    .myCoursesCostBefore{
        width: 10%;
        height: 100%;
        padding: 2px;
        display: flex;
        align-items: center;
       
    }
    .myCoursesYouSaved{
        width: 20%;
        height: 100%;
        padding: 2px;
        display: flex;
        align-items: center;
       
    }
    .myCoursesCostAfter{
        width: 20%;
        height: 100%;
        padding: 2px;
        padding-right:0px;
        /* display: flex; */
        /* align-items: center; */
       
    }
    .myCoursesCostAfterSingle{
        width: 37%;
        height: 100%;
        padding: 2px;
        padding-right:0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
       
    }
    .myCartMainHeading{
        font-size: 25px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
    }

    .myCartandLearn{
        font-size: 16px;
        font-family: Ubuntu-LI;
        font-weight: 500;
        color: #4A4A4A !important;
    }
    .myCoursesTitle{
        font-size: 18px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;

    }
    .myCoursesRemove{
        font-size: 14px;
        font-family: Ubuntu-Ri;
        font-weight: 500;
        color: #C01825 !important;


    }
    .myCoursesCost{
        font-size: 14px;
        font-family: Ubuntu-R;
        font-weight: 100;
        color: #6A6A6A !important;

    }
    .myCoursesFinalCost{
        font-size: 16px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
        padding-right: 8%;

    }
    .myCartHR{
        border-top: 1px solid #E5E5E5;
        width: 100%;
        margin: 0% 0% 2% 0%;

    }

    .myCartPromoCode{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0% 0% 3% 0% !important

    }
    .myCartItem{
        font-size: 22px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
        margin-bottom: 5px;
    }
   
    .myCartPromoCodeInputMain{
        width: 100%;
        display: flex;
        flex-direction: row;
       
        justify-content: space-between;

    }
    .myCartPromoCodeInputtypeDiv{
        width: 30%;
        display: flex;
        align-items: center;
    }

    .myCartPromoCodeApply{
        width: 70%;
        display: flex;
        align-items: center;
        margin-left: 2%;
        color: #C01825 !important;
       

    }
    .myCartAddress{
        width: 100% !important;
        height:auto;
        display: flex;
        flex-direction: row;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: 2% 0% 2% 0% !important;
        z-index: 0;
        border-radius: 5px;
        padding: 1% 5% 1% 1%

    }
.myCartAddressH{
    width: 20%;
}



.myCartTotal{ align-items:center;
    width: 100% !important;
    height:auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  

}
.myCartButton {
    display: flex;
    width: 92%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 2%;
}

.myCartAddressH{
    font-size: 20px;
    font-family: Ubuntu-R;
    font-weight: 600;
    color: #4A4A4A !important;
    width: 30%;

}
.myCartAddressN{
    width: 55%;
  
}
.myCartAddressN2{
    font-size: 14px;
    color:#6a6a6a
}
.myCartAddressButton{
    font-size: 18px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 3%;

}




.myCartAddressN1{
    font-size: 20px;
    font-family: Ubuntu-R;
    font-weight: 600;
    color: #4A4A4A !important;


}
.myCartAddressN1{
    font-size: 16px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;

}

.myCartTotalName{
    font-size: 20px;
    /* font-family: Ubuntu-R; */
    font-weight: 600;
    color: #4A4A4A !important;
    text-align: end;
}
.myCartTotalValue{
    font-size: 16px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;
}

.myCartButtonEdit{
    width: 100px;
    height: 40px;
    margin-right: 2%;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #4A4A4A;
    text-align: center;
    color: #4A4A4A;
}
.myCartPromoCodeInput{
    height: 45px;
        }
.myCartButtonNew{
    width: 100px;
    height: 40px;
    margin-left: 2%;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #4A4A4A;
    text-align: center;
    color: #4A4A4A;
}
 
}
@media only screen and (min-width: 1100px)   {
  
}










@media only screen and (min-width: 1300px)  and (min-height:668px){     
    .styles--sc-shopping-list--empty--1isYv {
        -webkit-box-shadow: 0 0 2px #dedfe0;
        box-shadow: 0 0 2px #dedfe0;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        margin-bottom: 30px;
        padding: 10px;
        text-align: center;
    }  
  }



/* Large screens ----------- */
@media only screen  and (min-width : 1800px)  {
 
}

 

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {


}

.promo-item{
    border-bottom: 1px solid grey;
    border-bottom-style: dashed;
    margin: 0px 0px 0px 40px;
    padding:20px 15px;
}
.promo-item .promo-name > span{
    display: inline-flex;
    /* justify-content: space-around; */
    font-family: Ubuntu-R;
    min-width: 105px;
    height: 35px;
    /* border: 1px solid #daceb7; */
    align-items: center;
    max-width: 40%;

}
.promo-item .promo-name span span:first-child{
    padding:5px 8px;
    border: 1px solid #daceb7;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color:#fffae6;
}

.promo-item .promo-name span span:nth-child(2){
    padding:5px 8px;
    border: 1px solid #daceb7;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color:#fffae6;

}

.promo-item .apply-promo-button{
    background-color:white;
    border:1px solid #fc8019;
    color: #fc8019;
    box-shadow:unset;
    font-family: Ubuntu-B;
}


.promo-item .apply-promo-button:hover{

    color: #fc8019;
    box-shadow:inset;
}


.promo-item .promo-heading{
    font-family: Ubuntu-B;
    font-size:16px;
}


.promo-item .promo-image{
    width:20px;
    height:20px;

    margin-right:5px;
}

.promo-container{
    border: 1px solid #e9e9eb;
    margin: 40px 100px 10px 30px;
}


.promo-modal-container{
    width:600px !important
}


.cart-invoice-modal{
    /* position:absolute;
    background-color:white;
    border:1px solid black;
    left:30%;
    top:19px;
    width:50vw */

    margin:10px;
    padding:10px

}
.myCartTotalName2{
    font-size: 18px;
}
.save_pdf_button_container{
display: flex;
width:100%;
align-items: center;
justify-content: flex-end;
}
.save_pdf_button{
    font-size: 15px;
    width: 70px;
    
    }
    .invoice_container{
        width: 100vw;
        height: 200vh;
       position: absolute;
        background-color: #ffffffe3;
        top: 0;
        left: 0;
        z-index: 1111;
        margin: auto;
}



@media(max-width:768px)  {


    .myCartMain{
    width: 100%;
    /*display: flex;
    flex-direction: row;
*/
    margin-left: 5%;
    margin-right: 5%;
    background-color: #F4F4F4 !important;
    }

    /* .myCartAccountNav{

        width: 30%;
        background-color: #FFF !important;
      
    
    

    } */
    
    .RegistrationButton1{
        display: flex;
        margin-right: 2%;
        font-size: 16px;
    }
    .RegistrationButton2{
        display: flex;
        font-size: 16px;
    }

    .ButtonReset{
        width: 160px;
        height: 40px;
        border-radius: 5px;
        background-color: #C01825;
        border: 1px solid #C01825;
        text-align: center;
        color: #FFF0E9;

    }

    .ButtonReset:hover {
 
      box-shadow: 0 5px 15px #C01825;
    }
    .ButtonConfirm{
        width: 160px;
        height: 40px;
        border-radius: 5px;
        background-color: #0093D9;
        border: 1px solid #0093D9;
        text-align: center;
        color: #FFF0E9;
    }

    .ButtonConfirm:hover {
 
      box-shadow: 0 5px 15px #0093D9;
    }

    .myCartDetail{

        width: 60%;
        display: flex;
        background-color: #FFF !important;
        flex-direction: column;
        padding: 2%;

    

    }

    .myCourses{

        width: 100% !important;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: start;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: 2% 0% 2% 0% !important;
        z-index: 0;
        padding-top:10px;
        border-radius: 5px;

    

    }

    .myCoursesImg{

        width: 10%;
        height: 100%;
        padding: 2px;
        border-radius: 5px;
       display: flex;
       align-items: center;
       
        
    }
    

    .myCoursesNameAndDel{
        width: 30%;
        height: 100%;
        padding: 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 2%;
    }
    .myCoursesCostBefore{
        width: 10%;
        height: 100%;
        padding: 2px;
        display: flex;
        align-items: center;
       
    }
    .myCoursesYouSaved{
        width: 20%;
        height: 100%;
        padding: 2px;
        display: flex;
        align-items: center;
       
    }
    .myCoursesCostAfter{
        width: 10%;
        height: 100%;
        padding: 2px;
        padding-right:0px;
        display: flex;
        align-items: center;
       
    }
    .myCoursesCostAfterSingle{
        width: 37%;
        height: 100%;
        padding: 2px;
        padding-right:0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
       
    }
    .myCartMainHeading{
        font-size: 25px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
    }

    .myCartandLearn{
        font-size: 16px;
        font-family: Ubuntu-LI;
        font-weight: 500;
        color: #4A4A4A !important;
    }
    .myCoursesTitle{
        font-size: 18px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;

    }
    .myCoursesRemove{
        font-size: 14px;
        font-family: Ubuntu-Ri;
        font-weight: 500;
        color: #C01825 !important;


    }
    .myCoursesCost{
        font-size: 14px;
        font-family: Ubuntu-R;
        font-weight: 100;
        color: #6A6A6A !important;

    }
    .myCoursesFinalCost{
        font-size: 16px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
    

    }
    .myCartHR{
        border-top: 1px solid #E5E5E5;
        width: 100%;
        margin: 0% 0% 2% 0%;

    }

    .myCartPromoCode{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0% 0% 3% 0% !important

    }
    .myCartItem{
        font-size: 22px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
        margin-bottom: 5px;
    }
   
    .myCartPromoCodeInputMain{
        width: 100%;
        display: flex;
        flex-direction: row;
       
        justify-content: space-between;

    }
    .myCartPromoCodeInputtypeDiv{
        width: 30%;
        display: flex;
        align-items: center;
    }

    .myCartPromoCodeApply{
        width: 70%;
        display: flex;
        align-items: center;
        margin-left: 2%;
        color: #C01825 !important;
       

    }
    .myCartAddress{
        width: 100% !important;
        height:auto;
        display: flex;
        flex-direction: row;
        background-color: #F4F4F4 !important;
        border: 1px solid #E5E5E5 !important;
        box-shadow: none !important;
        color: #4A4A4A !important;
        margin: 2% 0% 2% 0% !important;
        z-index: 0;
        border-radius: 5px;
        padding: 1% 5% 1% 1%

    }
.myCartAddressH{
    width: 20%;
}



.myCartTotal{ align-items:center;
    width: 100% !important;
    height:auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  

}
.myCartButton {
    display: flex;
    width: 92%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 2%;
}

.myCartAddressH{
    font-size: 20px;
    font-family: Ubuntu-R;
    font-weight: 600;
    color: #4A4A4A !important;
    width: 30%;

}
.myCartAddressN{
    width: 55%;
  
}
.myCartAddressN2{
    font-size: 14px;
    color:#6a6a6a
}
.myCartAddressButton{
    font-size: 18px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 3%;

}




.myCartAddressN1{
    font-size: 20px;
    font-family: Ubuntu-R;
    font-weight: 600;
    color: #4A4A4A !important;


}
.myCartAddressN1{
    font-size: 16px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;

}

.myCartTotalName{
    font-size: 25px;
    font-family: Ubuntu-R;
    font-weight: 600;
    color: #4A4A4A !important;
    text-align: end;
}
.myCartTotalValue{
    font-size: 16px;
    font-family: Ubuntu-R;
    font-weight: 500;
    color: #4A4A4A !important;
}

.myCartButtonEdit{
    width: 100px;
    height: 40px;
    margin-right: 2%;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #4A4A4A;
    text-align: center;
    color: #4A4A4A;
}
.myCartPromoCodeInput{
    height: 45px;
        }
.myCartButtonNew{
    width: 100px;
    height: 40px;
    margin-left: 2%;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #4A4A4A;
    text-align: center;
    color: #4A4A4A;
}
.account-components-border .myCoursesImg > img{
    width:80px
}
#my-cart .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    width: 56vh !important;
    position: absolute !important;
    right: 0 !important;
    display: flex !important;
}
}

@media(max-width:525px){
    .tax-font{
font-size: 12px;
    }
.promo-modal-container{
    width:95% !important
}

    #my-cart .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: .3rem;
        outline: 0;
        width: 100% !important;
        position: absolute !important;
        right: 0 !important;
        display: flex !important;
    }
    .promo-container{
        border: 1px solid #e9e9eb;
        margin: 0px;
    }
    .rowDefault{
        margin-right: 0px !important;
    }
    .myCartAddressButton{
        font-size: 18px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;
        width: auto;
        display: flex;
        align-items: center;
        padding-left: 0%;
        padding-top: 5px;
        justify-content: flex-end;
    
    }
    .colDefault-mycart-mobile{

        position: static ;
  
        top: 31%;
        width: max-content;
        margin-left: auto;
    }
.rowDefault-mycart-mobile{
    flex-direction: column;
    margin-right: 0px !important;
}
 .rowDefault-mobile-width{

    margin-right: 2px !important;
 }
 .myCartTotalName2{
     font-size: 16px;
 }
    .myCartMain{
        width: 100%;
        /*display: flex;
        flex-direction: row;
    */
        margin-left: 5%;
        margin-right: 5%;
        background-color: #F4F4F4 !important;
        }
    
       /* #my-cart .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: .3rem;
            outline: 0;
            width: 56vh !important;
            position: absolute !important;
            right: 0 !important;
            display: flex !important;
        } */
        
        .myCartDetail{
    
            width: 60%;
            display: flex;
            background-color: #FFF !important;
            flex-direction: column;
            padding: 6% 6% 6% 8%;
    
        
    
        }
    
        .myCourses{
    
            width: 100% !important;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items:start;
            background-color: #F4F4F4 !important;
            border: 1px solid #E5E5E5 !important;
            box-shadow: none !important;
            color: #4A4A4A !important;
            margin: 2% 0% 2% 0% !important;
            z-index: 0;
            border-radius: 5px;
            padding-right:5px;
            padding-bottom: 5px;
    
        
    
        }
    .remove_icon{
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        color:#C01825 !important
    }
    .justify-content-flex-end{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
        .myCoursesImg{
    
            width: 100%;
            height: 100%;
            padding: 2px;
            border-radius: 5px;
            display: flex;
            align-items: center;
           
           
            
        }
    
        .myCoursesNameAndDel{
            width: 100%;
            height: 100%;
            padding: 2%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        }
        .myCoursesCostBefore{
            width: 100%;
            height: 100%;
            /* padding: 2%; */
            display: flex;
            justify-content: flex-end;
           
        }
        .myCoursesYouSaved{
            width: 100%;
            height: 100%;
            /* padding: 2%; */
            /* display: flex; */
            align-items: center;
            display: flex;
            justify-content: flex-end;
           
        }
        .myCoursesCostAfter{
            width: 100%;
            height: 100%;
            /* padding: 2% 0% 0% 0%; */
            padding-right:0px;
            display: flex;
            align-items: center;
           
        }
        .myCoursesCostAfterSingle {
            width: 35%;
            height: 50%;
            padding: 2px;
            padding-right: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            right: 5px;
            top: 27%;
        }
        .myCartMainHeading{
            font-size: 25px;
            font-family: Ubuntu-R;
            font-weight: 600;
            color: #4A4A4A !important;
        }
    
        .myCartandLearn{
            font-size: 16px;
            font-family: Ubuntu-LI;
            font-weight: 500;
            color: #4A4A4A !important;
        }
        .myCoursesTitle{
            font-size: 18px;
            font-family: Ubuntu-R;
            font-weight: 500;
            color: #4A4A4A !important;
    
        }
        .myCoursesRemove{
            font-size: 14px;
            font-family: Ubuntu-Ri;
            font-weight: 500;
            color: #C01825 !important;
            text-align: end;
            padding-right: 5px;
            margin-top: 25px;
    
    
        }
        .myCoursesCost {
            font-size: 14px;
            font-family: Ubuntu-R;
            font-weight: 100;
            color: #6A6A6A !important;
        }
        .myCoursesFinalCost{
            font-size: 14px;
            font-family: Ubuntu-R;
            font-weight: 600;
            color: #4A4A4A !important;
            margin-left:auto;
    
        }
        .myCartHR{
            border-top: 1px solid #E5E5E5;
            width: 100%;
            margin: 0% 0% 2% 0%;
    
        }
    
        .myCartPromoCode{
      width: 100%;
      display: flex;
      flex-direction: column;
    
        }
        .myCartItem{
            font-size: 16px;
            font-family: Ubuntu-R;
            font-weight: 600;
            color: #4A4A4A !important;
        }
        .myCartPromoCodeInputMain{
            width: 80%;
            display: flex;
            flex-direction: row;
            margin-left: 35px;
    
        }
        .myCartPromoCodeInputtypeDiv{
            width: 100%;
            display: flex;
            align-items: center;
        }
    
        .myCartPromoCodeApply{
            width: auto;
            display: flex;
            align-items: center;
            margin-left: 2%;
            color: #C01825 !important;
           
    
        }
        .myCartAddress{
            width: 100% !important;
            height:auto;
            display: flex;
            flex-direction: column;
            background-color: #F4F4F4 !important;
            border: 1px solid #E5E5E5 !important;
            box-shadow: none !important;
            color: #4A4A4A !important;
            margin: 4% 0% 2% 0% !important;
            z-index: 0;
            border-radius: 5px;
            padding: 2% 5% 2% 5%;
    
        }
    .myCartAddressH{
        width: 100%;
    }
    
    
    
    .myCartTotal{ align-items:center;
        width: 100% !important;
        height:auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      
    
    }
    .myCartButton {
        display: flex;
        width: 95%;
        justify-content: flex-end;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 2%;
    }
    
    .myCartAddressH{
        font-size: 14px;
        font-family: Ubuntu-R;
        font-weight: normal;
        color: #4A4A4A !important;
        width: 100%;
    
    }
    .myCartAddressN{
        width: 100%;
      
    }
    .myCartAddressButton{
        font-size: 18px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;
        width: 100%;
        display: flex;
        align-items: center;
    
    }
    
    
    
    
    .myCartAddressN1{
        font-size: 20px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
    
    
    }
    .myCartAddressN1{
        font-size: 16px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;
    
    }
    
    .myCartTotalName{
        font-size: 16px;
        font-family: Ubuntu-R;
        font-weight: 600;
        color: #4A4A4A !important;
        text-align: end;
    }
    .myCartTotalValue{
        font-size: 16px;
        font-family: Ubuntu-R;
        font-weight: 500;
        color: #4A4A4A !important;
    }
    
    .myCartButtonEdit{
        width: 100px;
        height: 35px;
        margin-right: -1%;
        border-radius: 5px;
        background-color: transparent;
        border: 0px solid #4A4A4A;
        text-align: end;
        color: #c01825;
    }
    .myCartPromoCodeInput{
height: 30px;
    }
    .myCartButtonNew{
        width: 100px;
        height: 40px;
        margin-left: 2%;
        border-radius: 5px;
        background-color: #FFF;
        border: 1px solid #4A4A4A;
        text-align: center;
        color: #4A4A4A;
    }

    .account-components-border .margin-top-mobile{
        margin-top:20px
    }
    .account-components-border .myCoursesImg {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .account-components-border .myCoursesImg > img{
        width:200px
    }
    .account-components-border .myCourses {
        display:flex;
        flex-direction: column;
    }

    .account-components-border .account-components-border .myCoursesNameAndDel {
        width:50%;
        font-size:12px !important;
    }

    .account-components-border .myCoursesNameAndDel{
        font-size:12px
    }

    .account-components-border  .myCoursesTitle {
        font-size: 16px !important;
        }

    .account-components-border  .test_cost_cart {
        font-size:14px !important;
        width: 70px;
    }
}