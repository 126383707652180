
    .locationForm{
        padding: 10px;
        width: 34%;
        margin: initial;
    }
    .mapShadow{
        box-shadow: inset 0 0 10px #D7D7D7;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
    }
    .edit_location_header_div{
        padding: 10px 3%;
        width: 40vw;
        margin: 1% 2%;
        height: auto;
        background-color: #fff;
        box-shadow: grey 1px 1px 7px 1px;
        display: flex;
        flex-direction: column;
    }
    .footer_select{
        display: flex;
        flex-direction: column
    }
    .tabPadding{
        padding: 5px;
        margin: 8px 0px
    }
    .tabDiv{
        display: flex;
        margin: 2% 0;
        justify-content: space-between
    }
    .input-calendar .icon-wrapper {
        cursor: pointer;
    }
    .input-calendar .calendar-icon {
        display: inline-block;
        padding: 0px 1px 0 5px;
        height: 27px;
        line-height: 27px;
        -webkit-transform: translateY(4px);
        -ms-transform: translateY(4px);
        transform: translateY(4px);
        cursor: pointer;
    }
    .input-calendar {
        font-family: 'Pontano Sans', sans-serif;
        position: relative;
    }
    .input-calendar-wrapper {
        position: absolute;
        padding: 5px 12px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        -webkit-box-shadow: 0 0 9px 5px rgba(0,0,0,0.05);
        box-shadow: 0 0 9px 5px rgba(0,0,0,0.05);
        background-color: #fff;
        text-align: center;
        left: 140px;
        z-index: 2;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }