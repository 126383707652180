.ExamsSet {
    padding: 20px 80px;
}

.ExamData {
    border-bottom: double #c01825;
    /* border-top: double #c01825; */
    padding: 10px;
    width: fit-content;
    margin-bottom: 50px;
}

.SubjectName {
    font-size: 25px;
    font-family: OpenSans-B;
    font-weight: 600;
    text-decoration: underline
}

.ExamsMainHeading {
    font-weight: 600;
    font-size: 40px;
    position: absolute;
    top: 40%;
    left: 45%;
    color: #fff;

}

.TestName {
    font-size: 18px;
    font-family: OpenSans-R;
}

.TestData {
    justify-content: space-between;
    width: 50vw;
}

.StartTestBtn {
    min-width: 100px;
    height: 35px;
    background-color: #c01825;
    border: 1px solid #c01825;
    color: white;
}

.BannerImageDiv {
    position: relative;
}

.BannerImage {
    height: 330px;
    width: 100%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .TestData {
        justify-content: space-between;
        width: 70vw;
    }


    .ExamsSet {
        padding: 30px
    }
}



@media only screen and (max-width: 768px) {
    .TestData {
        justify-content: space-between;
        width: 90vw;
    }


    .ExamsSet {
        /* padding: 10px */
    }
}

@media(max-width:600px) {
    .BannerImage {
        height: 250px;
    }

    .ExamsMainHeading {
        display: none;
    }

    .selectSubjectExplanation {
        justify-content: center;
        align-items: center;
    }

    .ExamsSet {
        padding: 25px
    }
    .SubjectName{
        font-size: 20px;
    }
    .TestName{
        font-size: 16px;
    }
    .StartTestBtn{
        font-size: 15px;
        height: 30px;
    }
}