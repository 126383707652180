.sliderImage {
  display: none;
}

.BoxDiv:hover {
  overflow-y: scroll;
}

.BoxDiv {
  overflow-y: scroll;
  /* max-height: 200px; */
}
.BoxDiv1 {
  padding: 0 20px;
  overflow-y: hidden;
}

.Partition {
  height: 570px !important;
}

.Homepage {
  padding: 0 2px;
  overflow: hidden;
}
.TextBelowBannerImage {
  font-size: 16px;
  font-weight: 400;
}
.TextBelowBannerImageBlue {
  color: rgb(55, 167, 211);
}
.Bullets {
  margin-left: -15px;
  margin-right: 10px;
}
.Banner {
  padding-top: 20px;
  padding-bottom: 30px;
  /* border-bottom: 2px solid red; */
}
.BannerHeading {
  font-size: 21px;
  display: block;
  margin-bottom: 150x;
  text-align: center;
  letter-spacing: -0.5px;
  /* font-weight: bold; */
}

.Hr {
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid #c01825;
  border-bottom: 1px solid #c01825;

  padding: 1px;
}

.BannerImage {
  padding: 10px;
}
.ImageDiv {
  margin-bottom: 40px;
  text-align: center;

}
.Image {
  width: 100%;
  margin-bottom: 10px;
}
.BackCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: pink;
}
.BackCardButton {
  background-color: white;
  border: none !important;
  margin-bottom: 40px !important;
  color: black !important;
  border-radius: 0px !important;
  margin-top: -15px;
}
.BackCardTitle {
  color: white;
  margin-top: 30px;
  padding: 0 10px;
  text-align: center;
}
/* .BackContent{
        text-align: center;
        margin-top: 30px;
        padding-right: 13px;
        padding-left: 13px;
        color: white;
    }
    .BackPara{
        margin-top: 30px;
        text-align: center;
        padding-right: 13px;
        padding-left: 13px;
        font-family: 'OpenSans-R';
        font-size: 12px;
        color: white;
    } */
.BackCardText {
  color: white;
  font-size: 13px;
  padding: 10px 20px;
  text-align: center;
}
.TextBelowImages {
  text-align: center;
}

/*/////////////////////////////////////////////////////////// CONTENT STUFF/////////////////////////////////// */
.Content {
  display: flex;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
}

.ContentAbout {
  background-color: rgb(247, 242, 242);
  text-align: left;
  padding-top: 10px;
  padding-right: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* position: relative; */
  padding-bottom: 20px;
  align-items: center;
  width: 30%;
}
.ContentAbout :hover {
  cursor: pointer;
}
.ContentUpdates {
  background-color: rgb(233, 231, 231);
  text-align: left;
  padding-top: 10px;
  padding-right: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
  width: 30%;
}
.ContentUpdates:hover {
  cursor: pointer;
}
.ContentDates {
  width: 30%;
  background-color: rgb(247, 242, 242);
  text-align: left;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.ContentDates:hover {
  cursor: pointer;
}
.ContentCourses {
  text-align: center;
  width: 15%;
  margin-left: 10px;
}
.CourseTitle {
  font-size: 30px;
  text-align: center;
}
.Courses {
  padding-left: 83px;
  padding-right: 96px;
}

.ContentCourses div {
  cursor: pointer;
}
.AboutTitle {
  text-align: center;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.AboutDescription {
  /* line-height: 1.6; */
  padding-right: 3px;
  padding-left: 15px;
  margin-bottom: 20px;
}
.AboutPoints {
  /* list-style: none */
  list-style-type: none;
}
.AboutPoints li {
  margin-bottom: 15px;
}
.UpdateTitle {
  padding-bottom: 10px;
  /* padding-left: 15px; */
  padding-top: 10px;
}
.UpdatePoints {
  list-style-type: none;
  padding-left: 33px;
}
.DatePoints {
  list-style-type: none;
  padding-left: 35px;
}
.UpdatePoints li {
  margin-bottom: 15px;
  padding: 0px 25px 0px 0px;
}

.DatePoints li {
  margin-bottom: 15px;
}
.DateTitle {
  /* padding-left: 15px; */
  padding-bottom: 10px;
  padding-top: 10px;
}
.ContentCourseImage {
  /* background-color: red; */
  max-width: 100%;
  object-fit: cover;
  height: 100px;
}

.ContentCourseImageOdd {
  max-width: 100%;
  object-fit: cover;
  height: 100px;
}

.OuterContentCourse {
  margin-bottom: 15px;
  height: 226px;
  padding: 2px;
}

.OuterContentCourseOdd {
  margin-bottom: 15px;

  padding: 2px;
}
.ContentCourse {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 220px;
}
.OuterContentCourseCA {
  border: 1px solid #65ac4c;
  margin-bottom: 15px;

  padding: 2px;
}
.ContentCourseCA {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #65ac4c;
}
.OuterContentCourseSCHOOL {
  border: 1px solid #dac100;
  margin-bottom: 15px;

  padding: 2px;
}
.ContentCourseSCHOOL {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #dac100;
}
.OuterContentCourseUG {
  border: 1px solid #8fabff;
  margin-bottom: 15px;

  padding: 2px;
}
.ContentCourseUG {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #8fabff;
}

.OuterContentCourseOTHER {
  border: 1px solid #c01825;
  margin-bottom: 15px;

  padding: 2px;
}
.Abc {
  background-color: pink;
  width: 100%;
}
.ContentCourseOTHER {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #c01825;
}
.ContentCourseText {
  display: block;
  margin-top: 20px;
  font-weight: bold;
  padding: 0 15px;
}

.BtnContent {
  background-color: #c01825;
  color: white;
  padding: 8px 30px;
  border: none;
  margin-top: 15px;
}
.angleDown {
  font-size: 25px;
  border: 1px solid #c01825;
  padding: 8px 12px;
  border-radius: 50%;
}
.angleDown:hover {
  cursor: pointer;
}
.downArrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Cards {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  flex: 0 0 19%;
}
.Card {
  width: 20.5%;
  padding-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: red; */
  margin: 0px 25px !important;
}
.Card:hover {
  cursor: pointer;
}
.CardImage {
  /* width: 100%; */
  /* height: 180px; */
  margin-bottom: 30px;
}
.TextCardFlag {
  padding: 5px 20px;
  text-align: center;
  font-size: 15px;
  width: 100%;
  color: white;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TextCardUniversity {
  background-color: #8fabff;
  padding: 5px 20px;
  text-align: center;
  font-size: 15px;
  color: white;
  min-height: 55px;
}
.TextCardLearn {
  background-color: #dac100;
  padding: 5px 20px;
  text-align: center;
  font-size: 15px;
  color: white;
  min-height: 55px;
}
.TextCardGanesha {
  background-color: #c01825;
  padding: 5px 20px;
  text-align: center;
  font-size: 15px;
  color: white;
  min-height: 55px;
}
.TextCardCA {
  background-color: #65ac4c;
  padding: 5px 20px;
  text-align: center;
  font-size: 15px;
  color: white;
  min-height: 55px;
}

/* .CardImageGanesha{
       height: 180px;
       margin-bottom: 30px;
    } */

@media only screen and (min-width: 1800px) {
  .OuterContentCourse {
    margin-bottom: 15px;
    height: 227px;
    padding: 2px;
  }

  .ContentCourse {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 219px;
  }
}

@media only screen and (max-width: 1024px) {
  .Content {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .BackCardButton {
    margin-top: 0;
  }
  .ContentDates {
    height: 730px !important;
  }
  .ContentAbout {
    height: 730px !important;
  }
  .ContentUpdates {
    height: 730px !important;
  }
  .CardImage {
    /* height: 183px; */
  }
  .TextCardFlag {
    padding: 14px 10px;
    min-height: 77px;
  }
  .TextCardLearn {
    padding: 14px 10px;
    min-height: 77px;
  }
  .TextCardUniversity {
    padding: 14px 10px;
    min-height: 77px;
  }
  .TextCardGanesha {
    padding: 14px 10px;
    min-height: 77px;
  }
  .TextCardCA {
    padding: 14px 10px;
    min-height: 77px;
  }
  .BackContent {
    margin-top: 30px;
    font-size: 15px;
  }
  .BackPara {
    /* margin-top: 8px; */
    display: none;
  }
  .ContentCourses {
    width: 20%;
  }
  .Cards {
    display: flex;
    flex-wrap: wrap;
  }
  .Card {
    flex: 0 0 31.5%;
  }
}

.Content {
  padding-right: 40px;
  padding-left: 40px;
}
.Courses {
  padding-right: 50px;
  padding-left: 50px;
}

.ContentAbout {
  width: 26%;
  height: 950px;
}
.ContentUpdates {
  width: 26%;
  height: 950px;
}
.ContentDates {
  width: 26%;
  height: 950px;
}
.ContentCourses {
  width: 15%;
}
@media only screen and (max-width: 900px) {
  .BannerImage{
    padding:0
  }
  .BackCardButton {
    font-weight: bold;
  }

  .BackCardText {
    font-size: 17px;
  }
  .BtnContent {
    margin-bottom: 15px;
  }
  .AboutTitle {
    display: flex;
    justify-content: center;
  }
  .opensansRLine {
    font-size: 22px !important;
  }
  .DateTitle {
    display: flex;
    justify-content: center;
  }

  .TextCardFlag {
    height: 90px;
  }
  .ContentDates {
    height: 380px !important;
  }
  .ContentAbout {
    height: 380px !important;
  }
  .ContentUpdates {
    height: 380px !important;
  }
  .TextCardFlag {
    padding: 26px 10px;
    font-size: 17px;
  }
  .TextCardUniversity {
    padding: 26px 10px;
    font-size: 17px;
  }
  .TextCardLearn {
    padding: 26px 10px;
    font-size: 17px;
  }
  .TextCardGanesha {
    padding: 26px 10px;
    font-size: 17px;
  }
  .TextCardCA {
    padding: 26px 10px;
    font-size: 17px;
  }
  .Cards {
    display: flex;
    flex-wrap: wrap;
  }
  .Card {
    flex: 0 0 42%;
    margin-top: 30px;
  }
  .BackContent {
    font-size: 18px;
  }
  .BackPara {
    display: block;
    font-size: 15px;
  }

  .BannerHeading {
    font-size: 22px;
    /* font-weight: bold; */
  }
  .TextBelowBannerImage {
    font-size: 26px;
  }
  .TextBelowImages {
    font-size: 22px;
    display: none;
  }
  .carouselImage {
    height: 200px !important;
  }
  .AboutDescription{
    overflow-y: scroll;
  }
  .Hr{
    margin-top: 20px;
  }

  .Content {
    flex-direction: column;
    padding-right: 40px;
    padding-left: 40px;
  }
  .Courses {
    margin-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .ContentAbout {
    width: 100%;
  }
  .ContentUpdates {
    width: 100%;
  }
  .ContentDates {
    width: 100%;
  }
  .ContentCourses {
    /* width: 100%; */
    display: none;
  }
  .sliderImage {
    display: none;
  }
  .UpdateTitle {
    display: flex;
    justify-content: center;
  }
  .BtnContent {
    margin-bottom: 15px;
  }
  .ContentUpdates {
    padding-bottom: 13px;
  }
  .BackCardTitle {
    font-size: 21px;
  }
}
@media only screen and (max-width: 576px) {
  .BannerHeading {
    display: none;
  }
  .TextBelowImages {
    display: none;
  }
  .BannerImage {
    padding: 0px;
  }
  .downArrow {
    display: none;
  }
  .Hr {
    display: none;
  }
  .Banner {
    padding: 0px;
  }
  .content {
    padding: 1% !important;
  }

  .ContentCourseText {
    font-size: 14px;
    padding: 4px 15px;
  }

  .ContentAbout {
    display: none;
  }

  .ContentUpdates {
    display: none;
  }

  .ContentDates {
    display: none;
  }

  .Courses {
    display: none;
  }

  .Banner {
    display: block;
  }
  .sliderImage {
    display: none;
  }

  .ContentCourses {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0%;
  }

  .ContentCourse {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: 186px !important;
  }

  .Content {
    padding: 5% 0% !important;
    display: flex;
  }

  .OuterContentCourse {
    width: 48%;
    margin: 1%;
    height: 192px;
  }
  .carouselImage {
    height: 150px !important;
  }

  .OuterContentCourseOdd {
    width: 100%;
    margin: 1%;
    margin-bottom: 15px;

    padding: 2px;
  }

  .ContentCourseImage {
    /* background-color: red; */
    max-width: 100%;

    height: 85px;
  }

  .ContentCourseImageOdd {
    max-width: 100%;
    object-fit: cover;

    height: 100px;
  }

  .Cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .TextCardCA {
    padding: 15px 5px;
    min-height: 50px;
    font-size: 15px;
  }
  .TextCardGanesha {
    padding: 15px 5px;
    min-height: 50px;
    font-size: 15px;
  }
  .TextCardFlag {
    padding: 15px 5px;
    min-height: 50px;
    font-size: 15px;
  }
  .TextCardUniversity {
    padding: 15px 5px;
    min-height: 50px;
    font-size: 15px;
  }
  .TextCardLearn {
    padding: 15px 5px;
    min-height: 50px;
    font-size: 15px;
  }
  .Card {
    width: 90%;
    margin-top: 0px;
  }
  .Content {
    padding-right: 10px;
    padding-left: 10px;
  }
  .Courses {
    padding-right: 10px;
    padding-left: 10px;
  }

  .TextBelowBannerImage {
    font-size: 15px;
  }
  .TextBelowImages {
    font-size: 13px;
  }
  .ContentAbout {
    width: 100%;
    padding-top: 30px;
  }
  .ContentDates {
    padding-top: 30px;
  }
  .ContentUpdates {
    padding-top: 30px;
  }
  .UpdatePoints {
    font-size: 13px;
  }
  .BtnContent {
    font-size: 13px;
    padding: 5px 15px;
    border: none !important;
  }
  .DatePoints {
    font-size: 13px;
  }
  .DateTitle {
    font-size: 25px;
  }
  .AboutTitle {
    text-align: center;
    font-size: 25px;
  }

  .AboutDescription {
    font-size: 15px;
  }
  .AboutPoints {
    font-size: 13px;
  }

  .UpdateTitle {
    text-align: center;
    font-size: 25px;
  }
  .DateTitle {
    text-align: center;
  }
  .AboutDescription {
    text-align: center;
  }
  .AboutPoints {
    /* text-align: center; */
  }
  .ContentButton {
    bottom: 0;
    left: 35%;
    /* margin-bottom: 20px; */
  }
  .AboutPoints {
    margin-bottom: 60px;
  }
  .UpdatePoints {
    margin-bottom: 60px;
  }
  .DatePoints {
    margin-bottom: 60px;
  }
}

/* Portrait */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .ContentCourseImage {
    height: 75px;
  }

  .ContentCourseImageOdd {
    height: 75px;
  }
  .ContentCourse {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 180px;
  }

  .OuterContentCourse {
    height: 188px;
    padding: 3px;
  }
  .OuterContentCourseOdd {
    padding: 3px;
  }

  .ContentCourseText {
    font-size: 14px;
  }
  .ContentAbout {
    height: 815px !important;
  }

  .ContentUpdates {
    height: 815px !important;
  }
  .ContentDates {
    height: 815px !important;
  }

  .ContentCourseText {
    margin-top: 5px;
  }

  .Card {
    flex: 0 0 44.5%;
    width: 19%;
  }
}

/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}
@media (max-width: 600px) {
  .carou {
    width: 200% !important;
  }
}

.latestUpdateKeyPoints:hover {
  cursor: pointer !important;
}
