/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                            html                                         


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

html {
  scroll-behavior: smooth !important;
}


body {
  overflow-x: hidden;
  font-family: OpenSans-R;
}

a {
  all: unset;
  color: black
}

#footer li {
  cursor: pointer
}

#footer li a {
  all: unset;
  color: #666666;
  cursor: pointer
}


/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                              Font                                            


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

@font-face {
  font-family: "OpenSans-R";
  src: url("../Assets/Fonts/OpenSans-Regular_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-B";
  src: url("../Assets/Fonts/OpenSans-Bold_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-I";
  src: url("../Assets/Fonts/OpenSans-Italic_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-L";
  src: url("../Assets/Fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-LI";
  src: url("../Assets/Fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kruti-Dev-Regular";
  src: url("../Assets/Fonts/Kruti-Dev-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
    font-family: 'Kruti-Dev-Bold';
    src: url('../Assets/Fonts/Kruti-Dev-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */

/* .opensansI{

  font-family: 'OpenSans-I';
}


.opensansL{

  font-family: 'OpenSans-L';
}
.opensansLI{
  font-family: 'OpenSans-LI';
} */



.logged-out-searchbar>div {
  width: 100%
}

.KrutiDev {
  font-family: "Kruti-Dev-Regular";
}

.opensansR {
  font-family: "OpenSans-R";
}

.KrutiDevRLine {
  font-family: "Kruti-Dev-Regular";
  font-size: 16px;

  line-height: 28px;
}

.opensansRLine {
  font-family: "OpenSans-R";
  font-size: 16px;

  line-height: 28px;
}

.KrutiDevB {
  font-family: "Kruti-Dev-Regular";

  font-weight: bold;
}

.opensansB {
  font-family: "OpenSans-B";
}

.KrutiDevH {
  font-family: "Kruti-Dev-Regular";
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.opensansH {
  font-family: "OpenSans-B";
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}


/* Portrait */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .opensansRB {

    font-size: 12px;
  }

  .opensansB {
    font-size: 16px;
  }



}





/* Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {}










@media only screen and (max-width: 900px) {
  .contact-us-sticky-footer-button {
    font-size: 21px;
    padding: 4px 10px;
  }

  .opensansRLine {
    font-family: "OpenSans-R";
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .modal-dialog {
    display: flex;
  }

  .opensansH {
    font-family: "OpenSans-B";
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0.8px;
  }

  #civil-services {
    min-height: 80vh;
  }

}

/* 
  @font-face {
    font-family: "Flaticon";
    src: url("../Assets/Fonts/Icons/font/Flaticon.eot")format('truetype'),
    
         url("../Assets/Fonts/Icons/font/Flaticon.woff") format("woff"),
         url("../Assets/Fonts/Icons/font/Flaticon.ttf") format("truetype"),
         url("../Assets/Fonts/Icons/font/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
  }
   */

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                              Homepage                                           


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

@media (max-width: 576px) {
  #homepage {
    margin-top: 62px;
  }
}






@media (max-width: 769px) {
  #product-page {
    margin-top: 65px;
  }
}


#product-page .row_mobile {

  padding: 0 5%;
}

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                        Prof  10x10


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

.ProfTen {
  width: 100px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.ProfTenWalking {
  width: 135px
}

.tenten-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: fit-content;
  height: fit-content
}

.width-talking {
  width: 210px !important;
  transform: translateX(-50vw)
}


.dialogue-box {
  height: -moz-fit-content;
  height: fit-content;
  width: 290px;
  position: fixed;
  bottom: 130px;
  display: flex;
  align-items: center;
  left: calc(50vw - 85px);
  background-color: white;
  border: 3px solid #c50606;
  border-radius: 10px;
  padding: 10px;
  z-index: 999;
}


.tenten-container .walk-in {
  transform: translateX(-45vw);
  transition: transform 5s ease-in;
  width: 200px
}


.ProfTenTalking {

  width: 230px;
  transform: translateX(-50vw);
  position: fixed;
  right: 0;
  bottom: -25px;
  z-index: 998;
}

.tenten-container .walk-out{
    /* width: 200px;
    transform: translateX(-50vw);
    position: fixed;
    right: 0;
    bottom: -25px;
    z-index: 9999;
    transform: scaleX(-1); */

    transform: translateX(-100vw);
    transition: transform 5s ease-in;
    width: 200px
}

@keyframes walkin {
  0% {
    right: 0
  }

  50% {
    right: 25vw
  }

  100% {
    right: 50vw
  }
}


.ProfTenCross{
  width: fit-content;
  transform: translateX(-50vw);
  position: fixed;
  right: 200px;
  /* bottom: -25px; */
  z-index: 9999;
  bottom: 150px;
  cursor:pointer
}

/* 
.ProfTen{
  width: 90px;
  position: absolute;
right: 0;
bottom: 0;
z-index: 9999;

}

.ProfTenWalking{
  width: 100%;
    position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999;
  
  }
  
 
  
  .ProfTenXTenCloseBtn{
    position: absolute;
      bottom: 120px;
      z-index: 9999;
  
      left: 87px;
      color: #c01825 !important;
      font-size: 25px;
      font-weight: bold !important;
  
  
  
  }
   */

.react-pdf__Page canvas {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.ProfTenWalkingImg {
  width: 160px;
  height: 140px;
  z-index: 9999;
}

.ProfTenXTenCloseBtn:hover {
  color: #c01825 !important;
}

.ProfTenxTen {
  cursor: pointer;
}

@media (max-width: 768px) {
  .ProfTenxTen {
    display: none;
  }
}

/* 
.custom-cur {
  cursor: url('../../src/Assets/images/new10.gif'), auto;

} */

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                                   Sign-up                                            


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

#sign-up .default-placeholder .icon {
  border-radius: 2px;
  height: 15px;
  width: 16px;
  border: 0.11em solid #4a4a4a;
  margin-left: 10px;
  box-sizing: border-box;
  position: relative;
}

#sign-up .default-placeholder .icon .date-dots .dot {
  background-color: #4a4a4a !important;
  height: 2px;
  width: 2px;
  margin-bottom: 2px;
}

#sign-up .default-placeholder .icon .calendar-hooks .hook {
  height: 3px;
  border: 1px solid #4a4a4a !important;
}

#sign-up .default-placeholder {
  border-radius: 0px;
  box-shadow: 0 1px 6px -2px rgba(74, 102, 165, 0.1);
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 12px;
  letter-spacing: 0.2px;
  color: #b8c2cc;
  font-size: 90%;
  box-sizing: border-box;
  min-width: 200px;
}

@media (max-width: 576px) {
  #sign-up .default-placeholder {
    border-radius: 0px;
    box-shadow: 0 1px 6px -2px rgba(74, 102, 165, 0.1);
    background-color: #ffffff;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 12px;
    letter-spacing: 0.2px;
    color: #b8c2cc;
    font-size: 90%;
    box-sizing: border-box;
    min-width: 200px;
    height: 35px;
  }
}

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                            Login                                   


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

.modal{
  background-color: rgba(0,0,0,0.8) !important;
  display:block !important;
}


#LoginComponent .modal-header .close {
  margin: -4% -7% -13% 0%;
  color: #fff;
  font-size: 30px;
}

#LoginComponent .modal-header {
  padding: 0%;
}

#LoginComponent .modal-body {
  padding: 0%;
  border: 1px solid white;
}

.modal-content {
  width: 650px;
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
  #LoginComponent .modal-header .close {
    margin: -6% -4% -6% 0%;
    color: #4a4a4a;
    font-size: 30px;
  }

  .modal-content {
    width: 100%;
  }
}

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                      Mobile Header                                 


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

#header-mobile-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  /* background-color: #bc3a3b; */
  background-color: rgba(188, 58, 59, 0.95);
  overflow-x: hidden;

  transition: 0.5s;
  display: flex;
  flex-direction: column;
}

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                      Login Header                                 


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

.badge {
  display: inline-block;
  color: #c01825 !important;
  padding: 0px;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: -webkit-baseline-middle;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}





/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                    lessons

  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/


/* #lessonContent .pdfArrows{
  padding: 10px 0;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;

  color: #fff;

} */


#lessonContent .pdfArrows {
  padding: 10px 0;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  background-color: #d6d6d6;
  color: black;
  bottom: 0;
  z-index: 999;

  border: 1px solid #d6d6d6;

}

#lessonContent .pdfArrows:hover {
  border: 1px solid black;


}



#lessonContent .btn-default {
  background-color: #f4f4f4 !important;
  border: 1px solid rgb(218, 215, 215) !important;
  box-shadow: none !important;
}

#lessonContent .fa-plus {
  color: rgb(87, 85, 85);
}

#lessonContent .fa-minus {
  color: rgb(87, 85, 85);
}

@media only screen and (max-width: 900px) {

  /* #lessonContent span {
    color: black;
  } */
  #lessonContent text {
    color: black;
  }
}


@media only screen and (max-width: 800px) {
  .react-pdf__Document .react-pdf__Page__canvas {
    /* width: 100%!important;
      */
    margin: 45px auto !important;

  }


  #lessonContent .pdfArrows {
    padding: 10px 0;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    background-color: #d6d6d6;
    color: black;
    bottom: 0;
    z-index: 999;

    border: 1px solid #d6d6d6;
  }

}




.slick-prev,
.slick-next {

  z-index: 99999;
}



.slick-slider {

  width: 85vw;
}


.slick-prev:before,
.slick-next:before {

  color: black !important;
  font-size: 30px;

}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0 !important;
}



@media (max-width: 576px) {
  .slick-slider {
    width: 80vw;
  }
}




.admin-button-style {
  background: #0085ba;
  border-color: #006a95 #00648c #00648c;
  box-shadow: inset 0 -1px 0 #00648c;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 -1px 1px #005d82, 1px 0 1px #005d82, 0 1px 1px #005d82,
    -1px 0 1px #005d82;
  border-radius: 3px;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}

#add-subject-page label {
  margin-right: 0%;
  width: 26%;
  font-weight: bold;
}

#add-subject-page input {
  width: 60%;
  transition: all 0.3s;
  outline: none;
  box-shadow: none;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: transparent;
  box-sizing: border-box;
  background-color: white;
}

.ml-30px {
  margin-left: 30px;
}

#subject-page-list table th {
  font-size: 18px;
  font-weight: bolder;
}

#subject-page-list table tr {
  height: 30px;
}

#subject-page-list table {
  width: 97.5%;
  margin: 10px auto;
  box-shadow: 0px 1px 2px 1px #a29f9f;
}

#subject-page-list .pages_table tr:first-child th {
  padding: 5px 10px;
  font-size: 16px;
  font-family: Ubuntu-R;
}

.course-status-icon {
  width: 30px;
}

.subsubsub li span {
  font-family: Ubuntu-L;
}

.name_div_table>span {
  font-size: 15px;
}

.cross_icon {
  cursor: pointer;
}

.min-height-unset {
  min-height: unset !important;
}

.fit-content {
  height: fit-content;
}

.product-page {
  margin-top: 64px;
}

.exam-submenu-container,
#homepage .exam-submenu-container {
  overflow: hidden;
}

#homepage .flippy-front {
  padding: 0 !important;
  align-content: center;
  /* justify-content: center; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#homepage .flippy-back {
  padding: 0 !important;
}

.modal-content {}

.default-footer .buttons .today {
  visibility: hidden !important;
}

.default-footer .hint {
  display: none !important;
}

.wishlist-img {
  cursor: default;
  opacity: 0;
}

#snackbar {
  min-width: 250px;
  margin-left: -150px;
  background-color: #333333c7;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 50px;
  transition: display 0.05s fade;
}

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                    lessons Header

  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/

.CircularProgressbar {
  height: 35px !important;
  width: 35px !important;
  margin-right: 24px;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #c01825 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #c01825 !important;
}

@media (max-width: 576px) {
  .CircularProgressbar {
    height: 22px !important;
    width: 22px !important;
    margin-right: 10px;
  }
}


.react-pdf__Document .react-pdf__Page__canvas {
  width: 90% !important;
  height: auto !important;
  margin: 0 auto;

}

.react-autosuggest__input {
  position: absolute;
  right: 0;
  height: 39px;
  width: 300px;
}

.react-autosuggest__suggestions-list {
  z-index: 9999;
  position: absolute;
  top: 39px;
  right: 0;
}

.react-autosuggest__suggestions-list li {
  /* background-color: red; */
  /* height: 20px; */
  padding: 5px 5px;
}

.react-autosuggest__suggestion {
  background-color: white;
  width: 300px;

  margin-bottom: 1px solid black !important;
  list-style: none;
}

.react-autosuggest__suggestion:hover {
  cursor: pointer;
  background-color: #c01825;
  color: white;
}









/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                                   

                                       Test Series


  
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/


.thumbnailAdmin2 {
  width: 400px !important;
  height: 150px !important;
}

.mediaButton {
  outline: none !important;
  border: none !important;
}

.forgotAdminPass:hover {
  cursor: pointer;
}


.modalReport1 {
  position: fixed;
  top: 45%;
  left: 50%;
  height: 350px;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 3rem 2rem;
  /* background-color: #797777; */
  color: black;
  width: 50%;
  z-index: 999;
  font-size: 20px;
  border: 1px solid black;
  background-color: #f8f7f7;
  overflow-y: scroll;
}

.showtopic {}

.headingModal {
  text-align: center;
  font-size: 17px;
}

.iconModal {
  position: absolute;
  right: 20px;
  top: 10px;
}

.errorClass {
  border-color: red !important;
}

.errorLabel {
  color: red;
}

.css-26l3qy-menu {
  z-index: 9999999999 !important;
}

.lecturesViewClick:hover {
  cursor: pointer;
}

.searchUL {
  overflow-y: scroll;
  height: 78px;
  z-index: 9999;
}

.searchLI {
  margin-top: 0px;
}

.studentBought {
  display: flex;
  flex-direction: row;
  /* justify-content:space-between */
}

.selectSubjectExplanation .css-yk16xz-control{
  width: 200px;
}
.selectSubjectExplanation .css-1pahdxg-control{
  width: 200px;
}
.explanation{
  margin-top: 30px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.evalQuestion{
  font-weight:600;
}
.evalAnswerText{
  font-weight: 600;
}
.evalExplanationText{
  font-weight: 600;
}

.css-2613qy-menu{
  width: 400px !important;
}

.lecture-list-picky-container{
  width: 340px;
}

.clickTenTen{
  position: fixed;
  right: -100px;
  bottom: 160px;
  font-size: 16px;
  z-index: 999;
  background: white;
  border: 2px solid #c01825;
  padding: 5px 8px;
  animation: FadeInOut 6.35s infinite;
  animation-timing-function:cubic-bezier(0.075, 0.82, 0.165, 1)
}


@keyframes FadeInOut{
  0%{
    right:-50px
  }
  10%{
    right:0px
  }
 30%{
   right:0px
 }
 70%{
   right:0px
 }
  80%{
    right:-50px
  }

  90%{
    right:-100px
  }
}







input[type=number] {
  -moz-appearance: textfield;
}

.deleteTest:hover{
  cursor: pointer;
}